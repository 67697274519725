import {
  connect
} from "react-redux";

import selectors from "redux/selectors";
import actions from "redux/actions";

import TodoModal from "./TodoModalView.jsx";

const mapStateToProps = (state) => ({
  currentUser: selectors.currentUser.getCurrentUserData(state),
  accessToken: selectors.auth.getAccessToken(state),

  todo: selectors.todo.readTodo(state),
  create_todo: selectors.todo.createTodo(state),
  edit_todo: selectors.todo.editTodo(state),
  delete_todo: selectors.todo.deleteTodo(state),
  edit_todo_user: selectors.todo.editTodoUser(state),
  user_groups: selectors.todo.editTodoUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  readTodo: (accessToken) => dispatch(actions.todo.readTodo({
    accessToken: accessToken
  })),
  createTodo: (accessToken, data) => dispatch(actions.todo.createTodo({
    accessToken: accessToken,
    data
  })),
  editTodo: (accessToken, id, data) => dispatch(actions.todo.editTodo({
    accessToken: accessToken,
    id,
    data
  })),
  deleteTodo: (accessToken, id) => dispatch(actions.todo.deleteTodo({
    accessToken: accessToken,
    id
  })),
  editTodoUser: (accessToken, id, data) => dispatch(actions.todo.editTodoUser({
    accessToken: accessToken,
    id,
    data
  })),
  getUserGroups: (accessToken) => dispatch(actions.todo.editTodoUser({
    accessToken: accessToken
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TodoModal);