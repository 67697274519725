import types from "redux/types";



const initialState = {
  loading: false,
  success: false,
  data: [],
  error: []
}

const initialUserState = {
  accountInfo: {
    ...initialState,
  },
  tradeInfo: {
    ...initialState,
  },
  tradesOpen: {
    ...initialState,
  }
};

export default (state = initialUserState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {

    //create
    case types.dashboard.ACCOUNTS_INFO:
      return {
        ...state,
        accountInfo: {
          ...initialState,
          loading: true,
        }
      };
    case types.dashboard.ACCOUNTS_INFO_SUCCESS:
      return {
        ...state,
        accountInfo: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.dashboard.ACCOUNTS_INFO_ERROR:
      return {
        ...state,
        accountInfo: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };


    //update
    case types.dashboard.TRADES_INFO:
      return {
        ...state,
        tradeInfo: {
          ...initialState,
          loading: true,
        }
      };
    case types.dashboard.TRADES_INFO_SUCCESS:
      return {
        ...state,
        tradeInfo: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.dashboard.TRADES_INFO_ERROR:
      return {
        ...state,
        tradeInfo: {
          ...initialState,
          error: payload?.data,
        }
      };

  //open trades
    case types.dashboard.TRADES_OPEN:
      return {
        ...state,
        tradeOpen: {
          ...initialState,
          loading: true,
        }
      };
    case types.dashboard.TRADES_OPEN_SUCCESS:
      return {
        ...state,
        tradeOpen: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.dashboard.TRADES_OPEN_ERROR:
      return {
        ...state,
        tradeOpen: {
          ...initialState,
          error: payload?.data,
        }
      };
      
    default:
      return state;
  }
};