import {
  all,
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import types from "redux/types";
import {
  createAccountError,
  createAccountSuccess,
  updateAccountError,
  updateAccountSuccess,
  readAccountError,
  readAccountSuccess,
  readAllAccountsError,
  readAllAccountsSuccess,
  deleteAccountError,
  deleteAccountSuccess,
  readUsersSuccess,
  readUsersError,
  readBrokersSuccess,
  readBrokersError,
  runAccountsSuccess,
  runAccountsError,
  stopAccountsSuccess,
  stopAccountsError
} from "redux/actions/accounts";

import {
  createAccountReq,
  updateAccountReq,
  readAccountReq,
  readAllAccountsReq,
  deleteAccountReq,
  readAllUsers,
  readBrokers,
  runAccount,
  killAccount
} from "api/accounts";

import {
  message
} from "antd";

function* createAccount(action) {
  try {
    const response = yield call(createAccountReq, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    message.info('Account Successfully Created!!!');
    yield put(createAccountSuccess(response));

  } catch (error) {
    message.error('Error Creating Account. ' + error?.message.toString());
    yield put(createAccountError(error));
  }
}

function* createAccountSaga() {
  yield takeEvery(types.accounts.CREATE_ACCOUNT, createAccount);
}

function* updateAccount(action) {
  try {
    const response = yield call(updateAccountReq, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    message.success('Account Successfully Updated!!!');
    yield put(updateAccountSuccess(response));

  } catch (error) {
    message.error('Error Updating Account. ' + error?.message.toString());
    yield put(updateAccountError(error));
  }
}

function* updateAccountSaga() {
  yield takeEvery(types.accounts.UPDATE_ACCOUNT, updateAccount);
}

function* readAccount(action) {
  try {
    const response = yield call(readAccountReq, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    yield put(readAccountSuccess(response));

  } catch (error) {
    message.error('Error Reading Account. ' + error?.message.toString());
    yield put(readAccountError(error));
  }
}

function* readAccountSaga() {
  yield takeEvery(types.accounts.READ_ACCOUNT, readAccount);
}

function* readAllAccounts(action) {
  try {
    const response = yield call(readAllAccountsReq, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    yield put(readAllAccountsSuccess(response));

  } catch (error) {
    message.error('Error Reading Accounts. ' + error?.message.toString());
    yield put(readAllAccountsError(error));
  }
}

function* readAllAccountsSaga() {
  yield takeEvery(types.accounts.READ_ACCOUNTS, readAllAccounts);
}

function* deleteAccount(action) {
  try {
    const response = yield call(deleteAccountReq, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    message.success('Account Successfully Deleted!!!');
    yield put(deleteAccountSuccess(response));

  } catch (error) {
    message.error('Error Deleting Account. ' + error?.message.toString());
    yield put(deleteAccountError(error));
  }
}

function* deleteAccountSaga() {
  yield takeEvery(types.accounts.DELETE_ACCOUNT, deleteAccount);
}

//read all users
function* readUsers(action) {
  try {
    const response = yield call(readAllUsers, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    yield put(readUsersSuccess(response));

  } catch (error) {
    message.error('Error Reading Users. ' + error?.message.toString());
    yield put(readUsersError(error));
  }
}

function* readUsersSaga() {
  yield takeEvery(types.accounts.READ_USERS, readUsers);
}

function* readBrokersFn(action) {
  try {
    const response = yield call(readBrokers, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    yield put(readBrokersSuccess(response));

  } catch (error) {
    message.error('Error Reading Brokers : ' + error?.message.toString());
    yield put(readBrokersError(error));
  }
}

function* readBrokersSaga() {
  yield takeEvery(types.accounts.READ_BROKERS, readBrokersFn);
}

function* runAccountFn(action) {
  try {
    const messageLoader = message.loading(`Please wait, Starting Account ${action?.payload?.data[0]?.account_number}.`, 0);
    const response = yield call(runAccount, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    setTimeout(messageLoader, 1);
    message.success(`Account ${response?.response?.accounts[0]?.account_number} Successfully Started!!!`, 2)
    
    yield put(runAccountsSuccess(response));

  } catch (error) {
    message.error('Error running Account. ' + error?.message.toString());
    yield put(runAccountsError(error));
  }
}

function* runAccountSaga() {
  yield takeEvery(types.accounts.RUN_ACCOUNTS, runAccountFn);
}

function* stopAccount(action) {
  try {
    const messageLoader = message.loading(`Please wait, Stopping Account ${action?.payload?.data[0]?.account_number}.`, 5);
    const response = yield call(killAccount, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    setTimeout(messageLoader, 1);
    message.success(`Account ${response?.response?.accounts[0]?.account_number} Successfully Stopped!!!`, 2);
    yield put(stopAccountsSuccess(response));

  } catch (error) {
    message.error('Error Stopping Account. ' + error?.message.toString());
    yield put(stopAccountsError(error));
  }
}

function* stopAccountSaga() {
  yield takeEvery(types.accounts.STOP_ACCOUNTS, stopAccount);
}



//add all the saga
export default function* accountsSaga() {
  yield all([
    createAccountSaga(),
    updateAccountSaga(), 
    readAccountSaga(), 
    deleteAccountSaga(), 
    readAllAccountsSaga(),
    readUsersSaga(),
    readBrokersSaga(),
    runAccountSaga(),
    stopAccountSaga()
  ]);
}