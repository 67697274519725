import {
  all,
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import types from "redux/types";
import {
  createClientError,
  createClientSuccess,
  readClientError,
  readClientSuccess,
  editClientError,
  editClientSuccess,
  deleteClientError,
  deleteClientSuccess,
  createInterestError,
  createInterestSuccess,
  editInterestError,
  editInterestSuccess,
  deleteInterestError,
  deleteInterestSuccess,
  createInvestError,
  createInvestSuccess,
  editInvestError,
  editInvestSuccess,
  deleteInvestError,
  deleteInvestSuccess,
  readSummaryError,
  readSummarySuccess,
  readReportError,
  readReportSuccess,
  sendReportError,
  sendReportSuccess,


  createWPSuccess,
  createWPError,
  readWPError,
  readWPSuccess,
  updateWPSuccess,
  updateWPError,
  deleteWPError,
  deleteWPSuccess,

  readAClientError,
  readAClientSuccess,

  verifyClientSuccess,
  verifyClientError,

  activateClientSuccess,
  activateClientError,
  
} from "redux/actions/portfolio";

import {
  getAllClients,
  createClient,
  createInterest,
  createInvest,
  updateClient,
  updateInterest,
  updateInvest,
  deleteClient,
  deleteInterest,
  deleteInvest,
  readReport,
  readSummary,
  sendReport,

  createWP,
  readWP,
  updateWP,
  deleteWP,

  getClient,
  verifyClient,
  activateClient,
} from "api/portfolio";

import {
  message
} from "antd";

function* createClientReq(action) {
  try {
    const response = yield call(createClient, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    message.info('Client Successfully Created!!!');
    yield put(createClientSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Creating Client. ');
    yield put(createClientError(error));
  }
}

function* createClientSaga() {
  yield takeEvery(types.protfolio.CREATE_CLIENTS, createClientReq);
}
function* createInterestReq(action) {
  try {
    const response = yield call(createInterest, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    message.info('Interest Successfully Created!!!');
    yield put(createInterestSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Creating Interest. ');
    yield put(createInterestError(error));
  }
}

function* createInterestSaga() {
  yield takeEvery(types.protfolio.CREATE_INTEREST, createInterestReq);
}

function* createInvestReq(action) {
  try {
    const response = yield call(createInvest, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    message.info('Invest Successfully Created!!!');
    yield put(createInvestSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Creating Invest. ');
    yield put(createInvestError(error));
  }
}

function* createInvestSaga() {
  yield takeEvery(types.protfolio.CREATE_INVEST, createInvestReq);
}

function* updateClientReq(action) {
  try {
    const response = yield call(updateClient, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    message.info('Client Successfully Updated!!!');
    yield put(editClientSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error updating Client. ');
    yield put(editClientError(error));
  }
}

function* updateClientSaga() {
  yield takeEvery(types.protfolio.EDIT_CLIENTS, updateClientReq);
}

function* updateInvestReq(action) {
  try {
    const response = yield call(updateInvest, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    message.info('Invest Successfully Updated!!!');
    yield put(editInvestSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error updating Invest. ');
    yield put(editInvestError(error));
  }
}

function* updateInvestSaga() {
  yield takeEvery(types.protfolio.EDIT_INVEST, updateInvestReq);
}

function* updateInterestReq(action) {
  try {
    const response = yield call(updateInterest, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    message.info('Interest Successfully Updated!!!');
    yield put(editInterestSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error updating Interest. ');
    yield put(editInterestError(error));
  }
}

function* updateInterestSaga() {
  yield takeEvery(types.protfolio.EDIT_INTEREST, updateInterestReq);
}

function* deleteClientReq(action) {
  try {
    const response = yield call(deleteClient, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    message.info('Client Successfully Deleted!!!');
    yield put(deleteClientSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Deleteting Client. ');
    yield put(deleteClientError(error));
  }
}

function* deleteClientSaga() {
  yield takeEvery(types.protfolio.DELETE_CLIENTS, deleteClientReq);
}

function* deleteInvestReq(action) {
  try {
    
    const response = yield call(deleteInvest, action.payload);
    const {
      error
    } = response;
    
    if (error) throw error;
    if(response?.data?.error) throw response?.data;

    message.info('Invest Successfully Deleted!!!');
    yield put(deleteInvestSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Deleteting Invest. ');
    yield put(deleteInvestError(error));
  }
}

function* deleteInvestSaga() {
  yield takeEvery(types.protfolio.DELETE_INVEST, deleteInvestReq);
}

function* deleteInterestReq(action) {
  try {
    const response = yield call(deleteInterest, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    message.info('Interest Successfully Deleted!!!');
    yield put(deleteInterestSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Deleteting Interest. ');
    yield put(deleteInterestError(error));
  }
}

function* deleteInterestSaga() {
  yield takeEvery(types.protfolio.DELETE_INTEREST, deleteInterestReq);
}

function* readClientsReq(action) {
  try {
    const response = yield call(getAllClients, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    yield put(readClientSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Reading Clients. ');
    yield put(readClientError(error));
  }
}

function* readClientsSaga() {
  yield takeEvery(types.protfolio.READ_CLIENTS, readClientsReq);
}
function* readClientReq(action) {
  try {
    const response = yield call(getClient, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    yield put(readAClientSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Reading Client. ');
    yield put(readAClientError(error));
  }
}

function* readClientSaga() {
  yield takeEvery(types.protfolio.READ_CLIENT, readClientReq);
}
function* verifyClientReq(action) {
  try {
    const response = yield call(verifyClient, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;
    message.success('Succssfully Verified Email.');
    yield put(verifyClientSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Verifying Client');
    yield put(verifyClientError(error));
  }
}

function* verifyClientSaga() {
  yield takeEvery(types.protfolio.VERIFY_CLIENT, verifyClientReq);
}
function* activateClientReq(action) {
  try {
    const response = yield call(activateClient, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;
    message.success('Succssfully Activated Client.');
    yield put(activateClientSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Activating Client.');
    yield put(activateClientError(error));
  }
}

function* activateClientSaga() {
  yield takeEvery(types.protfolio.ACTIVATE_CLIENT, activateClientReq);
}

function* readSummaryReq(action) {
  try {
    const response = yield call(readSummary, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    yield put(readSummarySuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Reading Summary. ');
    yield put(readSummaryError(error));
  }
}

function* readSummarySaga() {
  yield takeEvery(types.protfolio.READ_SUMMARY, readSummaryReq);
}
function* readReportReq(action) {
  try {
    const response = yield call(readReport, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    yield put(readReportSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Reading Report. ');
    yield put(readReportError(error));
  }
}

function* readReportSaga() {
  yield takeEvery(types.protfolio.READ_REPORT, readReportReq);
}

function* sendReportReq(action) {
  try {
    const response = yield call(sendReport, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    message.info('All Reports Successfully Sent!!!');
    yield put(sendReportSuccess(response));
    
  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Sendnig Report Email. ');
    yield put(sendReportError(error));
  }
}

function* sendReportSaga() {
  yield takeEvery(types.protfolio.SEND_REPORT, sendReportReq);
}


function* createWPReq(action) {
  try {
    const response = yield call(createWP, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    message.info('Successfully Created Weekly Report.');
    yield put(createWPSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Creating Weekly Report. ');
    yield put(createWPError(error));
  }
}

function* createWPSaga() {
  yield takeEvery(types.protfolio.CREATE_WP, createWPReq);
}

function* readWPReq(action) {
  try {
    const response = yield call(readWP, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    // message.info('Successfully Created Weekly Report.');
    yield put(readWPSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Reading Weekly Report. ');
    yield put(readWPError(error));
  }
}
function* readWPSaga() {
  yield takeEvery(types.protfolio.READ_WP, readWPReq);
}

function* updateWPReq(action) {
  try {
    const response = yield call(updateWP, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    message.info('Successfully Updated Weekly Report.');
    yield put(updateWPSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Updating Weekly Report. ');
    yield put(updateWPError(error));
  }
}
function* updateWPSaga() {
  yield takeEvery(types.protfolio.UPDATE_WP, updateWPReq);
}


function* deleteWPReq(action) {
  try {
    const response = yield call(deleteWP, action.payload);

    const {
      error
    } = response;
    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    message.info('Successfully Deleted Weekly Report.');
    yield put(deleteWPSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error Deleting Weekly Report. ');
    yield put(deleteWPError(error));
  }
}
function* deleteWPSaga() {
  yield takeEvery(types.protfolio.DELETE_WP, deleteWPReq);
}

//add all the saga
export default function* iaccountsSaga() {
  yield all([
    readClientsSaga(),
    readClientSaga(),
    verifyClientSaga(),
    activateClientSaga(),
    createClientSaga(),
    createInterestSaga(),
    createInvestSaga(),
    updateClientSaga(),
    updateInterestSaga(),
    updateInvestSaga(),
    deleteClientSaga(),
    deleteInterestSaga(),
    deleteInvestSaga(),
    readSummarySaga(),
    readReportSaga(),
    sendReportSaga(),

    createWPSaga(),
    readWPSaga(),
    updateWPSaga(),
    deleteWPSaga(),
  ]);
}