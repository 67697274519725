import React from 'react';
import ReactNotification from 'react-notifications-component';
import { store } from 'react-notifications-component';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
  } from '@ant-design/icons';
import './notification.scss';
import './ReactNotifications.scss';

export default props => {
    return ( <ReactNotification /> )
}

export const showSuccessToastWithIcon = (  message ) => {
    const messageWithIcon = <div className="ReactNotifications__ContentWithIcon">
                <div className="ReactNotifications__Content">
                    {message}
                </div>
                <div className="ReactNotifications__Icon">
                    <CheckCircleOutlined />
                </div>
            </div>;

      showToast("", messageWithIcon, "success", false );
}

export const showErrorToastWithIcon = (  message ) => {
    const messageWithIcon = <div className="ReactNotifications__ContentWithIcon">
                <div className="ReactNotifications__Content">
                    {message}
                </div>
                <div className="ReactNotifications__Icon">
                    <CloseCircleOutlined />
                </div>
            </div>;

      showToast("", messageWithIcon, "danger", false );
}

export const showToast = ( title , text , type="default", showIcon=true ) => {
    store.addNotification({
        title,
        type,
        message: text,
        width: 500,
        container: "top-right",
        animationIn: ["animated", "bounceInRight", "faster"],
        animationOut: ["animated", "bounceOutRight", "faster"],
        dismiss: { duration: 3000, onScreen: false, showIcon }
      });
}