
export const createIAccount = (state) => {
  return state.iaccounts.create_i;
};

export const updateIAccount = (state) => {
  return state.iaccounts.update_i;
};

export const readIAccount = (state) => {
  return state.iaccounts.read_i;
};

export const readIDAccount = (state) => {
  return state.iaccounts.read_id;
};

export const readIDProfit = (state) => {
  return state.iaccounts.read_ip;
};

export const deleteIAccount = (state) => {
  return state.iaccounts.delete_i;
};
export const createIDAccount = (state) => {
  return state.iaccounts.create_id;
};
export const createIDProfit = (state) => {
  return state.iaccounts.create_ip;
};

export const updateIDAccount = (state) => {
  return state.iaccounts.update_id;
};

export const updateIDProfit = (state) => {
  return state.iaccounts.update_ip;
};

export const deleteIDAccount = (state) => {
  return state.iaccounts.delete_id;
};
export const deleteIDProfit = (state) => {
  return state.iaccounts.delete_ip;
};

export const readAllIAccount = (state) => {
  return state.iaccounts.readAll_i;
};

export const readISummary = (state) => {
  return state.iaccounts.isummary;
};

export const readIDailySummary = (state) => {
  return state.iaccounts.idaily_summary;
};

export const readCompanies = (state) => {
  return state.iaccounts.icompanies;
};

export const readTrades = (state) => {
  return state.iaccounts.itrades;
};


export const readDeposit = (state) => {
  return state.iaccounts.readDeposit;
};

export const updateDeposit = (state) => {
  return state.iaccounts.updateDeposit;
};

export const createDeposit = (state) => {
  return state.iaccounts.createDeposit;
};

export const readOverview = (state) => {
  return state.iaccounts.readOverview;
};