import types from "redux/types";

export const readTodo = (payload) => {
  return {
    type: types.todo.READ_TODO,
    payload,
  };
};
export const readTodoSuccess = (payload) => {
  return {
    type: types.todo.READ_TODO_SUCCESS,
    payload,
  };
};
export const readTodoError = (payload) => {
  return {
    type: types.todo.READ_TODO_ERROR,
    payload,
  };
};
export const createTodo = (payload) => {
  return {
    type: types.todo.CREATE_TODO,
    payload,
  };
};
export const createTodoSuccess = (payload) => {
  return {
    type: types.todo.CREATE_TODO_SUCCESS,
    payload,
  };
};
export const createTodoError = (payload) => {
  return {
    type: types.todo.CREATE_TODO_ERROR,
    payload,
  };
};

export const editTodo = (payload) => {
  return {
    type: types.todo.EDIT_TODO,
    payload,
  };
};
export const editTodoSuccess = (payload) => {
  return {
    type: types.todo.EDIT_TODO_SUCCESS,
    payload,
  };
};
export const editTodoError = (payload) => {
  return {
    type: types.todo.EDIT_TODO_ERROR,
    payload,
  };
};

export const deleteTodo = (payload) => {
  return {
    type: types.todo.DELETE_TODO,
    payload,
  };
};
export const deleteTodoSuccess = (payload) => {
  return {
    type: types.todo.DELETE_TODO_SUCCESS,
    payload,
  };
};
export const deleteTodoError = (payload) => {
  return {
    type: types.todo.DELETE_TODO_ERROR,
    payload,
  };
};


export const editTodoUser = (payload) => {
  return {
    type: types.todo.EDIT_TODO_USER,
    payload,
  };
};
export const editTodoUserSuccess = (payload) => {
  return {
    type: types.todo.EDIT_TODO_USER_SUCCESS,
    payload,
  };
};
export const editTodoUserError = (payload) => {
  return {
    type: types.todo.EDIT_TODO_USER_ERROR,
    payload,
  };
};

export const readTodoComment = (payload) => {
  return {
    type: types.todo.READ_TODOCOMMENTS,
    payload,
  };
};
export const readTodoCommentSuccess = (payload) => {
  return {
    type: types.todo.READ_TODOCOMMENTS_SUCCESS,
    payload,
  };
};
export const readTodoCommentError = (payload) => {
  return {
    type: types.todo.READ_TODOCOMMENTS_ERROR,
    payload,
  };
};

export const editTodoComment = (payload) => {
  return {
    type: types.todo.EDIT_TODOCOMMENTS,
    payload,
  };
};
export const editTodoCommentSuccess = (payload) => {
  return {
    type: types.todo.EDIT_TODOCOMMENTS_SUCCESS,
    payload,
  };
};
export const editTodoCommentError = (payload) => {
  return {
    type: types.todo.EDIT_TODOCOMMENTS_ERROR,
    payload,
  };
};

export const createTodoComment = (payload) => {
  return {
    type: types.todo.CREATE_TODOCOMMENTS,
    payload,
  };
};
export const createTodoCommentSuccess = (payload) => {
  return {
    type: types.todo.CREATE_TODOCOMMENTS_SUCCESS,
    payload,
  };
};
export const createTodoCommentError = (payload) => {
  return {
    type: types.todo.CREATE_TODOCOMMENTS_ERROR,
    payload,
  };
};

export const deleteTodoComment = (payload) => {
  return {
    type: types.todo.DELETE_TODOCOMMENTS,
    payload,
  };
};
export const deleteTodoCommentSuccess = (payload) => {
  return {
    type: types.todo.DELETE_TODOCOMMENTS_SUCCESS,
    payload,
  };
};
export const deleteTodoCommentError = (payload) => {
  return {
    type: types.todo.DELETE_TODOCOMMENTS_ERROR,
    payload,
  };
};


export const readTrades = (payload) => {
  return {
    type: types.todo.READ_TRADES,
    payload,
  };
};
export const readTradesSuccess = (payload) => {
  return {
    type: types.todo.READ_TRADES_SUCCESS,
    payload,
  };
};
export const readTradesError = (payload) => {
  return {
    type: types.todo.READ_TRADES_ERROR,
    payload,
  };
};


//name changed
export const readTLog = (payload) => {
  return {
    type: types.todo.READ_TODOCOMMENTS,
    payload,
  };
};

//name changed
export const editTLog = (payload) => {
  return {
    type: types.todo.EDIT_TODOCOMMENTS,
    payload,
  };
};

//name cahanged
export const deleteTLog = (payload) => {
  return {
    type: types.todo.DELETE_TODOCOMMENTS,
    payload,
  };
};


//login log 
export const LLog = (payload) => {
  return {
    type: types.todo.LOGINL,
    payload,
  };
};
export const LLogSuccess = (payload) => {
  return {
    type: types.todo.LOGINL_SUCCESS,
    payload,
  };
};
export const LLogError = (payload) => {
  return {
    type: types.todo.LOGINL_ERROR,
    payload,
  };
};

export const Uncommented = (payload) => {
  return {
    type: types.todo.UNCOMMENTED,
    payload,
  };
};
export const UncommentedSuccess = (payload) => {
  return {
    type: types.todo.UNCOMMENTED_SUCCESS,
    payload,
  };
};
export const UncommentedError = (payload) => {
  return {
    type: types.todo.UNCOMMENTED_ERROR,
    payload,
  };
};

export const Summary = (payload) => {
  return {
    type: types.todo.SUMMARY,
    payload,
  };
};
export const SummarySuccess = (payload) => {
  return {
    type: types.todo.SUMMARY_SUCCESS,
    payload,
  };
};
export const SummaryError = (payload) => {
  return {
    type: types.todo.SUMMARY_ERROR,
    payload,
  };
};
