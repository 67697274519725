import types from "redux/types";

const initialCurrentUserState = {
  loading: false,
  success: false,
  data: [],
  error: [],
  socialProfileLinks: {
    loading: false,
    success: false,
    data: [],
    error: [],
  },
  updateUserData: {
    loading: false,
    success: false,
    data: [],
    error: [],
  },
  updateGenres: {
    loading: false,
    success: false,
    data: [],
    error: [],
  }
};

export default (state = initialCurrentUserState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.currentUser.GET_CURRENT_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.currentUser.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: payload,
      };
    case types.currentUser.GET_CURRENT_USER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload,
      };

    case types.currentUser.ADD_SOCIAL_PROFILE_LINK_REQUEST:
      return {
        ...state,
        socialProfileLinks: {
          ...initialCurrentUserState.socialProfileLinks,
          loading: true,
        },
      };
    case types.currentUser.ADD_SOCIAL_PROFILE_LINK_SUCCESS:
      return {
        ...state,
        socialProfileLinks: {
          ...initialCurrentUserState.socialProfileLinks,
          loading: false,
          data: payload,
        },
      };
    case types.currentUser.ADD_SOCIAL_PROFILE_LINK_ERROR:
      return {
        ...state,
        socialProfileLinks: {
          ...initialCurrentUserState.socialProfileLinks,
          loading: false,
          error: payload,
        },
      };

    case types.currentUser.UPDATE_USER_DATA_REQUEST:
      return {
        ...state,
        updateUserData: {
          ...initialCurrentUserState.updateUserData,
          loading: true,
        },
      };
    case types.currentUser.UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
        updateUserData: {
          ...initialCurrentUserState.updateUserData,
          loading: false,
          success: true,
          data: payload,
        },
      };
    case types.currentUser.UPDATE_USER_DATA_ERROR:
      return {
        ...state,
        updateUserData: {
          ...initialCurrentUserState.updateUserData,
          loading: false,
          error: payload,
        },
      };

    case types.currentUser.ADD_USER_GENRES_REQUEST:
      return {
        ...state,
        updateGenres: {
          ...initialCurrentUserState.updateGenres,
          loading: true,
        },
      };
    case types.currentUser.ADD_USER_GENRES_SUCCESS:
      return {
        ...state,
        updateGenres: {
          ...initialCurrentUserState.updateGenres,
          loading: false,
          success: true,
          data: payload,
        },
      };
    case types.currentUser.ADD_USER_GENRES_ERROR:
      return {
        ...state,
        updateGenres: {
          ...initialCurrentUserState.updateGenres,
          loading: false,
          success: false,
          error: payload,
        },
      };

    case types.currentUser.GET_USER_SOCIAL_ANALYTICS_REQUEST:
      return {
        ...state,
        socialAnalytics: {
          loading: true,
        },
      };
    case types.currentUser.GET_USER_SOCIAL_ANALYTICS_SUCCESS:
      return {
        ...state,
        socialAnalytics: {
          loading: false,
          success: true,
          data: payload,
        },
      };
    case types.currentUser.GET_USER_SOCIAL_ANALYTICS_ERROR:
      return {
        ...state,
        socialAnalytics: {
          loading: false,
          success: false,
          error: payload,
        },
      };

    case types.currentUser.CLEAR:
      return {
        ...initialCurrentUserState,
      };

    case types.currentUser.CLEAR_UPDATE_USER_ERROR:
      return {
        ...state,
        updateUserData: {
          ...state.updateUserData,
          error: [],
        },
      };

    default:
      return state;
  }
};
