import types from "redux/types";

//update rules
export const updateRulesInit = (payload) => {
  return {
    type: types.rules.UPDATE_RULES,
    payload,
  };
};
export const updateRulesSuccess = (payload) => {
  return {
    type: types.rules.UPDATE_RULES_SUCCESS,
    payload,
  };
};
export const updateRulesError = (payload) => {
  return {
    type: types.rules.UPDATE_RULES_ERROR,
    payload,
  };
};

//read demo rules
export const readDemoRulesInit = (payload) => {
  return {
    type: types.rules.READ_DEMO_RULES,
    payload,
  };
};
export const readDemoRulesSuccess = (payload) => {
  return {
    type: types.rules.READ_DEMO_RULES_SUCCESS,
    payload,
  };
};
export const readDemoRulesError = (payload) => {
  return {
    type: types.rules.READ_DEMO_RULES_ERROR,
    payload,
  };
};

//read funded rules
export const readFundedRulesInit = (payload) => {
  return {
    type: types.rules.READ_FUNDED_RULES,
    payload,
  };
};
export const readFundedRulesSuccess = (payload) => {
  return {
    type: types.rules.READ_FUNDED_RULES_SUCCESS,
    payload,
  };
};
export const readFundedRulesError = (payload) => {
  return {
    type: types.rules.READ_FUNDED_RULES_ERROR,
    payload,
  };
};