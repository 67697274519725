import types from "redux/types";



const initialState = {
  loading: false,
  success: false,
  data: [],
  error: []
}

const initialRulesState = {
  update: {
    ...initialState,
  },
  readDemo: {
    ...initialState,
  },
  readFunded: {
    ...initialState,
  },
 
};

export default (state = initialRulesState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {

    //update
    case types.rules.UPDATE_RULES:
      return {
        ...state,
        update: {
          ...initialState,
          loading: true,
        }
      };
    case types.rules.UPDATE_RULES_SUCCESS:
      return {
        ...state,
        update: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.rules.UPDATE_RULES_ERROR:
      return {
        ...state,
        update: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };


    //read demo
    case types.rules.READ_DEMO_RULES:
      return {
        ...state,
        readDemo: {
          ...initialState,
          loading: true,
        }
      };
    case types.rules.READ_DEMO_RULES_SUCCESS:
      return {
        ...state,
        readDemo: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.rules.READ_DEMO_RULES_ERROR:
      return {
        ...state,
        readDemo: {
          ...initialState,
          error: payload?.data,
        }
      };


    //read funded
    case types.rules.READ_FUNDED_RULES:
      return {
        ...state,
        readFunded: {
          ...initialState,
          loading: true,
        }
      };
    case types.rules.READ_FUNDED_RULES_SUCCESS:
      return {
        ...state,
        readFunded: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.rules.READ_FUNDED_RULES_ERROR:
      return {
        ...state,
        readFunded: {
          ...initialState,
          error: payload?.data,
        }
      };

    default:
      return state;
  }
};