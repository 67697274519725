
export const getUsers = (state) => {
  return state.users.users;
};

export const getUserDelete = (state) => {
  return state.users.deleteUser;
};

export const getUserEdit = (state) => {
  return state.users.editUser;
};
