import types from "redux/types";

export const readStatisticsReq = (payload) => {
  return {
    type: types.statistics.READ_STATISTICS,
    payload,
  };
};
export const readStatisticsSuccess = (payload) => {
  return {
    type: types.statistics.READ_STATISTICS_SUCCESS,
    payload,
  };
};
export const readStatisticsError = (payload) => {
  return {
    type: types.statistics.READ_STATISTICS_ERROR,
    payload,
  };
};

//funded account
export const readFundedStatisticsReq = (payload) => {
  return {
    type: types.statistics.READ_FUNDED_STATISTICS,
    payload,
  };
};
export const readFundedStatisticsSuccess = (payload) => {
  return {
    type: types.statistics.READ_FUNDED_STATISTICS_SUCCESS,
    payload,
  };
};
export const readFundedStatisticsError = (payload) => {
  return {
    type: types.statistics.READ_FUNDED_STATISTICS_ERROR,
    payload,
  };
};

//by date
export const readStatisticsDateReq = (payload) => {
  return {
    type: types.statistics.READ_STATISTICS_DATE,
    payload,
  };
};

export const readStatisticsDateSuccess = (payload) => {
  return {
    type: types.statistics.READ_STATISTICS_DATE_SUCCESS,
    payload,
  };
};
export const readStatisticsDateError = (payload) => {
  return {
    type: types.statistics.READ_STATISTICS_DATE_ERROR,
    payload,
  };
};

export const readPropsReq = (payload) => {
  return {
    type: types.statistics.READ_PROPS,
    payload,
  };
};
export const readPropsSuccess = (payload) => {
  return {
    type: types.statistics.READ_PROPS_SUCCESS,
    payload,
  };
};
export const readPropsError = (payload) => {
  return {
    type: types.statistics.READ_PROPS_ERROR,
    payload,
  };
};