export default {

  READ_TODO: "/todo/read/request",
  READ_TODO_SUCCESS: "/todo/read/success",
  READ_TODO_ERROR: "/todo/read/error",

  EDIT_TODO: "/todo/edit/request",
  EDIT_TODO_SUCCESS: "/todo/edit/success",
  EDIT_TODO_ERROR: "/todo/edit/error",

  CREATE_TODO: "/todo/create/request",
  CREATE_TODO_SUCCESS: "/todo/create/success",
  CREATE_TODO_ERROR: "/todo/create/error",

  DELETE_TODO: "/todo/delete/request",
  DELETE_TODO_SUCCESS: "/todo/delete/success",
  DELETE_TODO_ERROR: "/todo/delete/error",
  
  EDIT_TODO_USER: "/todo_user/edit/request",
  EDIT_TODO_USER_SUCCESS: "/todo_user/edit/success",
  EDIT_TODO_USER_ERROR: "/todo_user/edit/error",

  READ_TODOCOMMENTS: "/todo_comment/read/request",
  READ_TODOCOMMENTS_SUCCESS: "/todo_comment/read/success",
  READ_TODOCOMMENTS_ERROR: "/todo_comment/read/error",

  CREATE_TODOCOMMENTS: "/todo_comment/create/request",
  CREATE_TODOCOMMENTS_SUCCESS: "/todo_comment/create/success",
  CREATE_TODOCOMMENTS_ERROR: "/todo_comment/create/error",

  EDIT_TODOCOMMENTS: "/todo_comment/edit/request",
  EDIT_TODOCOMMENTS_SUCCESS: "/todo_comment/edit/success",
  EDIT_TODOCOMMENTS_ERROR: "/todo_comment/edit/error",

  DELETE_TODOCOMMENTS: "/todo_comment/delete/request",
  DELETE_TODOCOMMENTS_SUCCESS: "/todo_comment/delete/success",
  DELETE_TODOCOMMENTS_ERROR: "/todo_comment/delete/error",

  READ_TRADES: "/tradelogs/read_trades/request",
  READ_TRADES_SUCCESS: "/tradelogs/read_trades/success",
  READ_TRADES_ERROR: "/tradelogs/read_trades/error",

  LOGINL: "/login/log/request",
  LOGINL_SUCCESS: "/login/log/success",
  LOGINL_ERROR: "/login/log/error",

  UNCOMMENTED: "trades/uncommened/request",
  UNCOMMENTED_SUCCESS: "trades/uncommened/success",
  UNCOMMENTED_ERROR: "trades/uncommened/error",

  SUMMARY: "trades/summary/request",
  SUMMARY_SUCCESS: "trades/summary/success",
  SUMMARY_ERROR: "trades/summary/error",
};