import types from "redux/types";

export const createAccountInit = (payload) => {
  return {
    type: types.accounts.CREATE_ACCOUNT,
    payload,
  };
};
export const createAccountSuccess = (payload) => {
  return {
    type: types.accounts.CREATE_ACCOUNT_SUCCESS,
    payload,
  };
};
export const createAccountError = (payload) => {
  return {
    type: types.accounts.CREATE_ACCOUNT_ERROR,
    payload,
  };
};


export const updateAccountInit = (payload) => {
  return {
    type: types.accounts.UPDATE_ACCOUNT,
    payload,
  };
};
export const updateAccountSuccess = (payload) => {
  return {
    type: types.accounts.UPDATE_ACCOUNT_SUCCESS,
    payload,
  };
};
export const updateAccountError = (payload) => {
  return {
    type: types.accounts.UPDATE_ACCOUNT_ERROR,
    payload,
  };
};


export const readAccountInit = (payload) => {
  return {
    type: types.accounts.READ_ACCOUNT,
    payload,
  };
};
export const readAccountSuccess = (payload) => {
  return {
    type: types.accounts.READ_ACCOUNT_SUCCESS,
    payload,
  };
};
export const readAccountError = (payload) => {
  return {
    type: types.accounts.READ_ACCOUNT_ERROR,
    payload,
  };
};



export const deleteAccountInit = (payload) => {
  return {
    type: types.accounts.DELETE_ACCOUNT,
    payload,
  };
};
export const deleteAccountSuccess = (payload) => {
  return {
    type: types.accounts.DELETE_ACCOUNT_SUCCESS,
    payload,
  };
};
export const deleteAccountError = (payload) => {
  return {
    type: types.accounts.DELETE_ACCOUNT_ERROR,
    payload,
  };
};


export const readAllAccountsInit = (payload) => {
  return {
    type: types.accounts.READ_ACCOUNTS,
    payload,
  };
};
export const readAllAccountsSuccess = (payload) => {
  return {
    type: types.accounts.READ_ACCOUNTS_SUCCESS,
    payload,
  };
};
export const readAllAccountsError = (payload) => {
  return {
    type: types.accounts.READ_ACCOUNTS_ERROR,
    payload,
  };
};


//
export const readUsersInit = (payload) => {
  return {
    type: types.accounts.READ_USERS,
    payload,
  };
};
export const readUsersSuccess = (payload) => {
  return {
    type: types.accounts.READ_USERS_SUCCESS,
    payload,
  };
};
export const readUsersError = (payload) => {
  return {
    type: types.accounts.READ_USERS_ERROR,
    payload,
  };
};

export const readBrokersInit = (payload) => {
  return {
    type: types.accounts.READ_BROKERS,
    payload,
  };
};
export const readBrokersSuccess = (payload) => {
  return {
    type: types.accounts.READ_BROKERS_SUCCESS,
    payload,
  };
};
export const readBrokersError = (payload) => {
  return {
    type: types.accounts.READ_BROKERS_ERROR,
    payload,
  };
};

export const runAccountsInit = (payload) => {
  return {
    type: types.accounts.RUN_ACCOUNTS,
    payload,
  };
};
export const runAccountsSuccess = (payload) => {
  return {
    type: types.accounts.RUN_ACCOUNTS_SUCCESS,
    payload,
  };
};
export const runAccountsError = (payload) => {
  return {
    type: types.accounts.RUN_ACCOUNTS_ERROR,
    payload,
  };
};

export const stopAccountsInit = (payload) => {
  return {
    type: types.accounts.STOP_ACCOUNTS,
    payload,
  };
};
export const stopAccountsSuccess = (payload) => {
  return {
    type: types.accounts.STOP_ACCOUNTS_SUCCESS,
    payload,
  };
};
export const stopAccountsError = (payload) => {
  return {
    type: types.accounts.STOP_ACCOUNTS_ERROR,
    payload,
  };
};