export default {
  GET_CURRENT_USER_REQUEST: "/users/current/request",
  GET_CURRENT_USER_SUCCESS: "/users/current/success",
  GET_CURRENT_USER_ERROR: "/users/current/error",
  UPDATE_USER_DATA_REQUEST: "/users/current/update/request",
  UPDATE_USER_DATA_SUCCESS: "/users/current/update/success",
  UPDATE_USER_DATA_ERROR: "/users/current/update/error",
  CLEAR_UPDATE_USER_ERROR: "users/current/update/clearErrors",
  CLEAR: "users/current/clear",
};
