export default {

  READ_STATISTICS: "/statistics/read/request",
  READ_STATISTICS_SUCCESS: "/statistics/read/success",
  READ_STATISTICS_ERROR: "/statistics/read/error",

  READ_FUNDED_STATISTICS: "/fundedStatistics/read/request",
  READ_FUNDED_STATISTICS_SUCCESS: "/fundedStatistics/read/success",
  READ_FUNDED_STATISTICS_ERROR: "/fundedStatistics/read/error",

  READ_STATISTICS_DATE: "/statisticsDate/read/request",
  READ_STATISTICS_DATE_SUCCESS: "/statisticsDate/read/success",
  READ_STATISTICS_DATE_ERROR: "/statisticsDate/read/error",
  
  READ_PROPS: "/props/read/request",
  READ_PROPS_SUCCESS: "/props/read/success",
  READ_PROPS_ERROR: "/props/read/error",
};