import { get, post, remove } from "utils/request";


export async function getAllClients(payload) {
  const {accessToken} = payload;
  return get("/clients/all", accessToken);
}

export async function getClient(payload) {
  const {id, accessToken} = payload;
  return get("/clients/read/"+id, accessToken);
}

export async function verifyClient(payload) {
  const {id, accessToken} = payload;
  return get("/clients/verify/"+id, accessToken);
}

export async function activateClient(payload) {
  const {id, accessToken} = payload;
  return get("/clients/activate/"+id, accessToken);
}

export async function createClient(payload) {
  const {accessToken, data} = payload;
  return post("/clients/create", data, accessToken);
}

export async function updateClient(payload) {
  const {accessToken, data, id} = payload;
  return post("/clients/update/"+id, data, accessToken);
}

export async function deleteClient(payload) {
  const {accessToken, id} = payload;
  return remove("/clients/remove/"+id, accessToken);
}

//interest
export async function createInterest(payload) {
  const {accessToken, data} = payload;
  return post("/clients/interest", data, accessToken);
}

export async function updateInterest(payload) {
  const {accessToken, data, id} = payload;
  return post("/clients/interest/"+id, data, accessToken);
}

export async function deleteInterest(payload) {
  const {accessToken, id} = payload;
  return remove("/clients/interest/"+id, accessToken);
}
//invest
export async function createInvest(payload) {
  const {accessToken, data} = payload;
  return post("/clients/invest", data, accessToken);
}

export async function updateInvest(payload) {
  const {accessToken, data, id} = payload;
  return post("/clients/invest/"+id, data, accessToken);
}

export async function deleteInvest(payload) {
  const {accessToken, id} = payload;
  return remove("/clients/invest/"+id, accessToken);
}

export async function readReport(payload) {
  const {accessToken, id} = payload;
  return get("/clients/summary/"+id, accessToken);
}

export async function readSummary(payload) {
  const {accessToken} = payload;
  return get("/clients/summary", accessToken);
}

export async function sendReport(payload) {
  const {accessToken, data} = payload;
  return post("/clients/sendReport", data, accessToken);
}


export async function createWP(payload) {
  const {accessToken, data} = payload;
  return post("/clients/weekly_profit", data, accessToken);
}
export async function updateWP(payload) {
  const {accessToken, data, id} = payload;
  return post("/clients/weekly_profit/"+id, data, accessToken);
}
export async function readWP(payload) {
  const {accessToken} = payload;
  return get("/clients/weekly_profits", accessToken);
}
export async function deleteWP(payload) {
  const {accessToken, id} = payload;
  return remove("/clients/weekly_profit/"+id, accessToken);
}