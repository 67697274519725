export default {
    
    GET_ALL_USERS: "/users/request",
    GET_ALL_USERS_SUCCESS: "/users/success",
    GET_ALL_USERS_ERROR: "/users/error",
    GET_ALL_USERS_RESET: "/users/reset",

    DELETE_USER: "/user/delete/request",
    DELETE_USER_SUCCESS: "/user/delete/success",
    DELETE_USER_ERROR: "/user/delete/error",

    EDIT_USER: "/user/edit/request",
    EDIT_USER_SUCCESS: "/user/edit/success",
    EDIT_USER_ERROR: "/user/edit/error",

  };
  