import types from "redux/types";



const initialState = {
  loading: false,
  success: false,
  data: [],
  error: []
}

const initialUserState = {
  create_i: {
    ...initialState,
  },
  update_i: {
    ...initialState,
  },
  read_i: {
    ...initialState,
  },
  dalete_i: {
    ...initialState,
  },
  read_id: {
    ...initialState,
  },
  create_id: {
    ...initialState,
  },
  update_id: {
    ...initialState,
  },
  dalete_id: {
    ...initialState,
  },
  read_ip: {
    ...initialState,
  },
  create_ip: {
    ...initialState,
  },
  update_ip: {
    ...initialState,
  },
  dalete_ip: {
    ...initialState,
  },
  readAll_i: {
    ...initialState
  },
  isummary: {
    ...initialState
  },
  idaily_summary: {
    ...initialState
  },
  icompanies: {
    ...initialState
  },
  itrades: {
    ...initialState
  },
  readDeposit:{
    ...initialState
  },
  updateDeposit:{
    ...initialState
  },
  createDeposit:{
    ...initialState
  },
  readOverview:{
    ...initialState
  }
};

export default (state = initialUserState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {

    //create
    case types.iaccounts.CREATE_IACCOUNT:
      return {
        ...state,
        create_i: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.CREATE_IACCOUNT_SUCCESS:
      return {
        ...state,
        create_i: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.CREATE_IACCOUNT_ERROR:
      return {
        ...state,
        create_i: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    case types.iaccounts.CREATE_IDACCOUNT:
      return {
        ...state,
        create_id: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.CREATE_IDACCOUNT_SUCCESS:
      return {
        ...state,
        create_id: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.CREATE_IDACCOUNT_ERROR:
      return {
        ...state,
        create_id: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    case types.iaccounts.CREATE_IDPROFIT:
      return {
        ...state,
        create_ip: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.CREATE_IDPROFIT_SUCCESS:
      return {
        ...state,
        create_ip: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.CREATE_IDPROFIT_ERROR:
      return {
        ...state,
        create_ip: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };


    //update
    case types.iaccounts.UPDATE_IACCOUNT:
      return {
        ...state,
        update_i: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.UPDATE_IACCOUNT_ERROR:
      return {
        ...state,
        update_i: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.UPDATE_IACCOUNT_SUCCESS:
      return {
        ...state,
        update_i: {
          ...initialState,
          error: payload?.data,
        }
      };

    case types.iaccounts.UPDATE_IDACCOUNT:
      return {
        ...state,
        update_id: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.UPDATE_IDACCOUNT_ERROR:
      return {
        ...state,
        update_id: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.UPDATE_IDACCOUNT_SUCCESS:
      return {
        ...state,
        update_id: {
          ...initialState,
          error: payload?.data,
        }
      };
    case types.iaccounts.UPDATE_IDPROFIT:
      return {
        ...state,
        update_ip: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.UPDATE_IDPROFIT_ERROR:
      return {
        ...state,
        update_ip: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.UPDATE_IDPROFIT_SUCCESS:
      return {
        ...state,
        update_ip: {
          ...initialState,
          error: payload?.data,
        }
      };


    //read
    case types.iaccounts.READ_IACCOUNT:
      return {
        ...state,
        read_i: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.READ_IACCOUNT_SUCCESS:
      return {
        ...state,
        read_i: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.READ_IACCOUNT_ERROR:
      return {
        ...state,
        read_i: {
          ...initialState,
          error: payload?.data,
        }
      };

    //read
    case types.iaccounts.READ_IDACCOUNT:
      return {
        ...state,
        read_id: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.READ_IDACCOUNT_SUCCESS:
      return {
        ...state,
        read_id: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.READ_IDACCOUNT_ERROR:
      return {
        ...state,
        read_id: {
          ...initialState,
          error: payload?.data,
        }
      };
    //read
    case types.iaccounts.READ_IDPROFIT:
      return {
        ...state,
        read_ip: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.READ_IDPROFIT_SUCCESS:
      return {
        ...state,
        read_ip: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.READ_IDPROFIT_ERROR:
      return {
        ...state,
        read_ip: {
          ...initialState,
          error: payload?.data,
        }
      };


    //delete
    case types.iaccounts.DELETE_IACCOUNT:
      return {
        ...state,
        delete_i: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.DELETE_IACCOUNT_SUCCESS:
      return {
        ...state,
        delete_i: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.DELETE_IACCOUNT_ERROR:
      return {
        ...state,
        delete_i: {
          ...initialState,
          error: payload?.data,
        }
      };

    case types.iaccounts.DELETE_IDACCOUNT:
      return {
        ...state,
        delete_id: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.DELETE_IDACCOUNT_SUCCESS:
      return {
        ...state,
        delete_id: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.DELETE_IDACCOUNT_ERROR:
      return {
        ...state,
        delete_id: {
          ...initialState,
          error: payload?.data,
        }
      };

    case types.iaccounts.DELETE_IDPROFIT:
      return {
        ...state,
        delete_ip: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.DELETE_IDPROFIT_SUCCESS:
      return {
        ...state,
        delete_ip: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.DELETE_IDPROFIT_ERROR:
      return {
        ...state,
        delete_ip: {
          ...initialState,
          error: payload?.data,
        }
      };


    //readAll
    case types.iaccounts.READ_IACCOUNTS:
      return {
        ...state,
        readAll_i: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.READ_IACCOUNTS_SUCCESS:
      return {
        ...state,
        readAll_i: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.READ_IACCOUNTS_ERROR:
      return {
        ...state,
        readAll_i: {
          ...initialState,
          error: action.payload,
        }
      };

    //Summary
    case types.iaccounts.READ_ISUMMARY:
      return {
        ...state,
        isummary: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.READ_ISUMMARY_SUCCESS:
      return {
        ...state,
        isummary: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.READ_ISUMMARY_ERROR:
      return {
        ...state,
        isummary: {
          ...initialState,
          error: action.payload,
        }
      };

      //Daily Summary
    case types.iaccounts.READ_IDAILYSUMMARY:
      return {
        ...state,
        idaily_summary: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.READ_IDAILYSUMMARY_SUCCESS:
      return {
        ...state,
        idaily_summary: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.READ_IDAILYSUMMARY_ERROR:
      return {
        ...state,
        idaily_summary: {
          ...initialState,
          error: action.payload,
        }
      };
    
      //companies summary
    case types.iaccounts.READ_COMPANIES:
      return {
        ...state,
        icompanies: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.READ_COMPANIES_SUCCESS:
      return {
        ...state,
        icompanies: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.READ_COMPANIES_ERROR:
      return {
        ...state,
        icompanies: {
          ...initialState,
          error: action.payload,
        }
      };

    //Read trades
    case types.iaccounts.READ_TRADES:
      return {
        ...state,
        itrades: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.READ_TRADES_SUCCESS:
      return {
        ...state,
        itrades: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.READ_TRADES_ERROR:
      return {
        ...state,
        itrades: {
          ...initialState,
          error: action.payload,
        }
      };
    //Deposit
    case types.iaccounts.READ_DEPOSIT:
      return {
        ...state,
        readDeposit: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.READ_DEPOSIT_SUCCESS:
      return {
        ...state,
        readDeposit: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.READ_DEPOSIT_ERROR:
      return {
        ...state,
        readDeposit: {
          ...initialState,
          error: action.payload,
        }
      };
    //Deposit
    case types.iaccounts.UPDATE_DEPOSIT:
      return {
        ...state,
        updateDeposit: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.UPDATE_DEPOSIT_SUCCESS:
      return {
        ...state,
        updateDeposit: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.UPDATE_DEPOSIT_ERROR:
      return {
        ...state,
        updateDeposit: {
          ...initialState,
          error: action.payload,
        }
      };
    //Deposit
    case types.iaccounts.CREATE_DEPOSIT:
      return {
        ...state,
        createDeposit: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.CREATE_DEPOSIT_SUCCESS:
      return {
        ...state,
        createDeposit: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.CREATE_DEPOSIT_ERROR:
      return {
        ...state,
        createDeposit: {
          ...initialState,
          error: action.payload,
        }
      };
    //Dashboard overview
    case types.iaccounts.READ_OVERVIEW:
      return {
        ...state,
        readOverview: {
          ...initialState,
          loading: true,
        }
      };
    case types.iaccounts.READ_OVERVIEW_SUCCESS:
      return {
        ...state,
        readOverview: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.iaccounts.READ_OVERVIEW_ERROR:
      return {
        ...state,
        readOverview: {
          ...initialState,
          error: action.payload,
        }
      };

    default:
      return state;
  }
};