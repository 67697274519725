import {
  all,
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import types from "redux/types";
import {
  updateRulesSuccess,
  updateRulesError,
  readDemoRulesSuccess,
  readDemoRulesError,
  readFundedRulesSuccess,
  readFundedRulesError
} from "redux/actions/rules";

import {
  read_demo,
  read_funded,
  update
} from "api/rules";

import {
  message
} from "antd";


//update rules saga
function* updateRules(action) {
  try {
    const response = yield call(update, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    message.info('Rules Updated!!!');
    yield put(updateRulesSuccess(response));

  } catch (error) {
    message.error('Error Updating Rules: ' + error?.message.toString());
    yield put(updateRulesError(error));
  }
}

function* updateRulesSaga() {
  yield takeEvery(types.rules.UPDATE_RULES, updateRules);
}


//read demo saga
function* readDemo(action) {
  try {
    const response = yield call(read_demo, action.payload);
    const {
      error
    } = response;
    if (error) throw error;

    yield put(readDemoRulesSuccess(response));

  } catch (error) {
    message.error('Error Reading Demo Rules: ' + error?.message.toString());
    yield put(readDemoRulesError(error));
  }
}

function* readDemoSaga() {
  yield takeEvery(types.rules.READ_DEMO_RULES, readDemo);
}

//read demo saga
function* readFunded(action) {
  try {
    const response = yield call(read_funded, action.payload);
    const {
      error
    } = response;
    if (error) throw error;

    yield put(readFundedRulesSuccess(response));

  } catch (error) {
    message.error('Error Reading Funded Rules: ' + error?.message.toString());
    yield put(readFundedRulesError(error));
  }
}

function* readFundedSaga() {
  yield takeEvery(types.rules.READ_FUNDED_RULES, readFunded);
}


//add all the saga
export default function* accountsSaga() {
  yield all([
    updateRulesSaga(),
    readDemoSaga(),
    readFundedSaga()
  ]);
}