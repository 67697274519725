import types from "redux/types";

export const getAllCUsersRequest = (payload) => {
  return {
    type: types.cUsers.GET_ALL_CUSERS,
    payload,
  };
};

export const getAllCUsersSuccess = (payload) => {
  return {
    type: types.cUsers.GET_ALL_CUSERS_SUCCESS,
    payload,
  };
};

export const getAllCUsersError = (error) => {
  return {
    type: types.cUsers.GET_ALL_CUSERS_ERROR,
    payload: error,
  };
};

//
export const deleteCUsersRequest = (payload) => {
  return {
    type: types.cUsers.DELETE_CUSER,
    payload
  };
};

export const deleteCUsersSuccess = (payload) => {
  return {
    type: types.cUsers.DELETE_CUSER_SUCCESS,
    payload,
  };
};

export const deleteCUsersError = (error) => {
  return {
    type: types.cUsers.DELETE_CUSER_ERROR,
    payload: error,
  };
};


//
export const verifyCUsersRequest = (payload) => {
  return {
    type: types.cUsers.VERIFY_CUSER,
    payload,
  };
};

export const verifyCUsersSuccess = (payload) => {
  return {
    type: types.cUsers.VERIFY_CUSER_SUCCESS,
    payload,
  };
};

export const verifyCUsersError = (error) => {
  return {
    type: types.cUsers.VERIFY_CUSER_ERROR,
    payload: error,
  };
};



// pending request
export const getAllPInvestRequest = (payload) => {
  return {
    type: types.cUsers.GET_ALL_PINVEST,
    payload,
  };
};

export const getAllPInvestSuccess = (payload) => {
  return {
    type: types.cUsers.GET_ALL_PINVEST_SUCCESS,
    payload,
  };
};

export const getAllPInvestError = (error) => {
  return {
    type: types.cUsers.GET_ALL_PINVEST_ERROR,
    payload: error,
  };
};

//
export const deletePInvestRequest = (payload) => {
  return {
    type: types.cUsers.DELETE_PINVEST,
    payload,
  };
};

export const deletePInvestSuccess = (payload) => {
  return {
    type: types.cUsers.DELETE_PINVEST_SUCCESS,
    payload,
  };
};

export const deletePInvestError = (error) => {
  return {
    type: types.cUsers.DELETE_PINVEST_ERROR,
    payload: error,
  };
};


//
export const verifyPInvestRequest = (payload) => {
  return {
    type: types.cUsers.VERIFY_PINVEST,
    payload,
  };
};

export const verifyPInvestSuccess = (payload) => {
  return {
    type: types.cUsers.VERIFY_PINVEST_SUCCESS,
    payload,
  };
};

export const verifyPInvestError = (error) => {
  return {
    type: types.cUsers.VERIFY_PINVEST_ERROR,
    payload: error,
  };
};

//
export const updateCUser = (payload) => {
  return {
    type: types.cUsers.UPDATE_CUSERS,
    payload,
  };
};

export const updateCUserSuccess = (payload) => {
  return {
    type: types.cUsers.UPDATE_CUSERS_SUCCESS,
    payload,
  };
};

export const updateCUserError = (error) => {
  return {
    type: types.cUsers.UPDATE_CUSERS_ERROR,
    payload: error,
  };
};