import types from "redux/types";



const initialState = {
  loading: false,
  success: false,
  data: [],
  error: []
}

const initialUserState = {
  create: {
    ...initialState,
  },
  update: {
    ...initialState,
  },
  read: {
    ...initialState,
  },
  dalete: {
    ...initialState,
  },
  readAll: {
    ...initialState
  },
  readUsers: {
    ...initialState
  },
  readBrokers: {
    ...initialState
  },
  runAccounts: {
    ...initialState
  },
  stopAccounts: {
    ...initialState
  }
};

export default (state = initialUserState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {

    //create
    case types.accounts.CREATE_ACCOUNT:
      return {
        ...state,
        create: {
          ...initialState,
          loading: true,
        }
      };
    case types.accounts.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        create: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.accounts.CREATE_ACCOUNT_ERROR:
      return {
        ...state,
        create: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };


    //update
    case types.accounts.UPDATE_ACCOUNT:
      return {
        ...state,
        update: {
          ...initialState,
          loading: true,
        }
      };
    case types.accounts.UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        update: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.accounts.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        update: {
          ...initialState,
          error: payload?.data,
        }
      };


    //read
    case types.accounts.READ_ACCOUNT:
      return {
        ...state,
        read: {
          ...initialState,
          loading: true,
        }
      };
    case types.accounts.READ_ACCOUNT_SUCCESS:
      return {
        ...state,
        read: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.accounts.READ_ACCOUNT_ERROR:
      return {
        ...state,
        read: {
          ...initialState,
          error: payload?.data,
        }
      };


    //delete
    case types.accounts.DELETE_ACCOUNT:
      return {
        ...state,
        delete: {
          ...initialState,
          loading: true,
        }
      };
    case types.accounts.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        delete: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.accounts.DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        delete: {
          ...initialState,
          error: payload?.data,
        }
      };


    //readAll
    case types.accounts.READ_ACCOUNTS:
      return {
        ...state,
        readAll: {
          ...initialState,
          loading: true,
        }
      };
    case types.accounts.READ_ACCOUNTS_SUCCESS:
      return {
        ...state,
        readAll: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.accounts.READ_ACCOUNTS_ERROR:
      return {
        ...state,
        readAll: {
          ...initialState,
          error: action.payload,
        }
      };

      //read all users
    case types.accounts.READ_USERS:
      return {
        ...state,
        readUsers: {
          ...initialState,
          loading: true,
        }
      };
    case types.accounts.READ_USERS_SUCCESS:
      return {
        ...state,
        readUsers: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.accounts.READ_USERS_ERROR:
      return {
        ...state,
        readUsers: {
          ...initialState,
          error: action.payload,
        }
      };

      //read all brokers
    case types.accounts.READ_BROKERS:
      return {
        ...state,
        readBrokers: {
          ...initialState,
          loading: true,
        }
      };
    case types.accounts.READ_BROKERS_SUCCESS:
      return {
        ...state,
        readBrokers: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.accounts.READ_BROKERS_ERROR:
      return {
        ...state,
        readBrokers: {
          ...initialState,
          error: action.payload,
        }
      };

      //run accounts
    case types.accounts.RUN_ACCOUNTS:
      return {
        ...state,
        runAccounts: {
          ...initialState,
          loading: true,
        }
      };
    case types.accounts.RUN_ACCOUNTS_SUCCESS:
      return {
        ...state,
        runAccounts: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.accounts.RUN_ACCOUNTS_ERROR:
      return {
        ...state,
        runAccounts: {
          ...initialState,
          error: action.payload,
        }
      };

      //kill accounts
    case types.accounts.STOP_ACCOUNTS:
      return {
        ...state,
        stopAccounts: {
          ...initialState,
          loading: true,
        }
      };
    case types.accounts.STOP_ACCOUNTS_SUCCESS:
      return {
        ...state,
        stopAccounts: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.accounts.STOP_ACCOUNTS_ERROR:
      return {
        ...state,
        stopAccounts: {
          ...initialState,
          error: action.payload,
        }
      };

    default:
      return state;
  }
};