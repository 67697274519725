export default {

  UPDATE_CHALLENGE: "/challenges/update/request",
  UPDATE_CHALLENGE_SUCCESS: "/challenges/update/success",
  UPDATE_CHALLENGE_ERROR: "/challenges/update/error",

  DELETE_CHALLENGE: "/challenges/delete/request",
  DELETE_CHALLENGE_SUCCESS: "/challenges/delete/success",
  DELETE_CHALLENGE_ERROR: "/challenges/delete/error",

  CREATE_CHALLENGE: "/challenges/create/request",
  CREATE_CHALLENGE_SUCCESS: "/challenges/create/success",
  CREATE_CHALLENGE_ERROR: "/challenges/create/error",

  UPDATE_CHALLENGE_STATUS: "/challenges/update_challenge_status/request",
  UPDATE_CHALLENGE_STATUS_SUCCESS: "/challenges/update_challenge_status/success",
  UPDATE_CHALLENGE_STATUS_ERROR: "/challenges/update_challenge_status/error",
};