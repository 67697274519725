
export const createChallenge = (state) => {
  return state.challenges.createChallenge;
};

export const updateChallenge = (state) => {
  return state.challenges.updateChallenge;
};

export const deleteChallenge = (state) => {
  return state.challenges.deleteChallenge;
};

export const updateChallengeStatus = (state) => {
  return state.challenges.updateChallengeStatus;
};
