import Routes from 'routes/Routes';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ReactNotifications from 'components/ReactNotifications/ReactNotifications';

import { store, persistor } from 'redux/store';
import 'bootstrap/scss/bootstrap.scss';
import 'antd/dist/antd.css';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactNotifications />
        <Routes />
      </PersistGate>
    </Provider>
  );
}

export default App;