import React, { useState } from "react";

import moment from "moment";
import './TodoList.scss';

import { Alert, message, Select, Tooltip, Modal } from "antd";
import { CloseOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
const { Option } = Select;



// React Todo Application

function uuid(len) {
    let length = len || 6;
    let charCodes = [];
    let string = '';

    for (let i = 0; i < 10; i++) {
        charCodes.push(48 + i);
        charCodes.push(97 + i);
    }
    for (let i = 0; i < 16; i++) {
        charCodes.push(107 + i);
    }

    for (let i = 0; i < length; i++) {
        let charIndex = Math.floor(Math.random() * charCodes.length);
        string = string + String.fromCharCode(charCodes[charIndex]);
    }

    return string;
}

function colour(bright) {
    let val;

    if (bright) {
        val = 'hsl(' + Math.floor(Math.random() * 360) + ', 100%, 60%)';
    }
    else {
        val = '#';
        let chars = '1234567890ABCDEF'.split('');
        for (let i = 0; i < 6; i++) {
            val += chars[Math.floor(Math.random() * chars.length)];
        }
    }
    return val;
}

class AddTask extends React.Component {

    componentDidUpdate(prevProps) {
        if (this.props.edit !== prevProps.edit) {
            this.setState({
                selectedTags: this.props.edit?.tags,
                selectedUsers: this.props.edit?.users,
                content: this.props.edit?.content,
                id: this.props.edit?.todo_id
            });
        }
    }

    constructor(props) {
        super();

        const tags = Array.isArray(props.all_tags) ? props.all_tags : [];

        //get all tag list for all
        const all_tags_ids = [];
        for (const tag of tags) {
            if (tag.id !== 'all') all_tags_ids.push(tag.id);
        }

        //initial state
        this.state = {
            id: null,
            content: '',
            selectedTags: [],
            selectedUsers: [],
            all_tags_ids: all_tags_ids,
            tags: tags
        };

    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.state.content === '') {
            message.error('Please enter a task');
        }
        else {

            if (!this.state.id) {
                this.props.addTask({
                    content: this.state.content,
                    completed: false,
                    tag: this.state.selectedTags,
                    users: this.state.selectedUsers
                });
            } else {

                this.props.updateTask({
                    id: this.state.id,
                    content: this.state.content,
                    completed: false,
                    tag: this.state.selectedTags,
                    users: this.state.selectedUsers
                });
            }
            this.clearTask();
        }

    }

    clearTask(e) {
        //e.preventDefault();
        this.setState({
            id: null,
            content: '',
            completed: false,
            selectedTags: [],
            selectedUsers: []
        });

        //this.props.edit = null;
    }



    handleChangeTag(value) {
        const contains_all = value.find(str => str === 'all');
        const tags = !contains_all ? value : this.state.all_tags_ids;
        this.setState({
            selectedTags: tags
        });
    }

    handleChangeUsers(value) {
        const contains_all = value.find(str => str === 'all');
        const tags = !contains_all ? value : this.state.all_tags_ids;
        this.setState({
            selectedTags: tags
        });
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit.bind(this)} className="task-form">
                <div className="task-input">
                    <input type="text" placeholder={this.state.id ? "Update Task" : "create new task"} value={this.state.content} onChange={(e) => this.setState({ content: e.target.value })} />
                </div>

                <Select
                    mode="multiple"
                    allowClear
                    placeholder="Tags/Categories"
                    defaultValue={this.state.selectedTags}
                    onChange={this.handleChangeTag.bind(this)}
                    className="tag-selector"
                    value={this.state.selectedTags}
                >
                    {Array.isArray(this.state.tags) && this.state.tags.map((tag) => {
                        return <Option key={tag?.id} value={tag?.id}>{tag?.name}</Option>
                    })}
                </Select>

                {/* <Select
                    mode="multiple"
                    allowClear
                    placeholder="Users"
                    defaultValue={this.state.selectedUsers}
                    onChange={this.handleChangeUsers.bind(this)}
                    className="tag-selector"
                >
                    {Array.isArray(this.state.tags) && this.state.tags.map((tag) => {
                        return <Option key={tag?.id} value={tag?.id}>{tag?.name}</Option>
                    })}
                </Select> */}

                <Tooltip title={this.state.id ? "Update Task" : "Add Task"}>
                    <button className="task-add-button" type="submit" value="Submit">
                        <svg viewBox="0 0 40 40">
                            <path d="M10 20 L30 20 M20 10 L20 30" />
                        </svg>
                    </button>
                </Tooltip>
                {this.state.id && <Tooltip title="Clear Task">
                    <button className="task-add-button task-item-remove" onClick={this.clearTask.bind(this)}>
                        <CloseOutlined />
                    </button>
                </Tooltip>}

            </form>
        );
    }
}

class TaskItem extends React.Component {
    removeTask(id) {
        this.props.onRemove(id);
    }

    editTask(task) {
        this.props.onEdit(task);
    }

    checkTask(id) {
        this.props.onCheck(id);
    }

    render() {
        const task = this.props.task;

        return (
            <li className="checklist-list">
                <div className="checklist-leftside">
                    {!this.props.showEditDelete &&
                        <input
                            id={this.props.task.id}
                            type="checkbox"
                            checked={this.props.task.completed}
                            onChange={this.checkTask.bind(this, task.id)} />
                    }
                    <label
                        className={!this.props.showEditDelete && task.expired ? 'expired-label' : null}
                        style={{ marginLeft: '10px' }}
                        htmlFor={this.props.task.id}>
                        {this.props.task.content}
                        <span
                            className="task-strike">
                        </span>
                    </label>
                    <span className="task-expire-info">
                        {task.expired ? <span style={{ color: 'red' }}>expired!</span> : <span style={{ color: 'green' }}>expires in {moment(task.expiresAt).fromNow(true)}</span>}
                    </span>
                </div>
                {this.props.showEditDelete &&
                    <div className="checklist-rightside">
                        <Tooltip title="edit todo">
                            <button
                                className="task-item-edit"
                                onClick={this.editTask.bind(this, task)}>
                                <EditOutlined className="small_icon" />
                            </button>
                        </Tooltip>
                        <Tooltip title="delete todo">
                            <button
                                className="task-item-remove"
                                onClick={this.removeTask.bind(this, task.todo_id)}>
                                <DeleteTwoTone twoToneColor="red" style={{ marginTop: '2.5px' }} />
                            </button>
                        </Tooltip>
                    </div>
                }
            </li>
        );

    }
}

class TaskList extends React.Component {
    render() {
        let allTasks = [];

        if (this.props.showEditDelete) {
            const tasks = Array.isArray(this?.props?.tasks) ? this.props.tasks : [];
            for (const task of tasks) {
                const taskIndex = allTasks.findIndex(t => t.todo_id === task.todo_id);

                if (taskIndex > -1) {
                    //get tags from exisinting tag list

                    const old_tags = Array.isArray(allTasks[taskIndex]?.tags) ? allTasks[taskIndex]?.tags : [];

                    //add current tags
                    let new_tags = [];
                    if (Array.isArray(task?.tags)) new_tags = old_tags.concat(task?.tags);

                    allTasks[taskIndex] = {
                        ...allTasks[taskIndex],
                        tags: new_tags
                    }
                } else {
                    allTasks.push(task);
                }
            }
        } else {
            allTasks = this.props?.tasks;
        }

        let taskItems = allTasks?.map(task => {
            return (
                <TaskItem
                    task={task}
                    key={task.id + "-" + task.todo_id}
                    onRemove={this.props.removeTask.bind(this)}
                    onEdit={this.props.editTask.bind(this)}
                    onCheck={this.props.checkTask.bind(this)}
                    showEditDelete={this.props.showEditDelete}
                    tags={this.props.tags} />
            )
        });

        return (
            <ul className="task-list">
                {taskItems}
            </ul>
        )
    }
}

class TaskControls extends React.Component {
    render() {
        let filters = this.props.filters;
        filters = filters.map(filter => {
            return (
                <button
                    key={filter.id}
                    onClick={this.props.setFilter.bind(this, filter)}
                    className={this.props.activeFilter === filter.name ? 'btn-active' : ''}>
                    {filter.label || filter.name}
                </button>
            );
        });

        return (
            <div className="task-controls">
                <span>{this.props.completed()} / {this.props.total()} Completed</span>
                {filters}
                {/* <button
                    onClick={this.props.clearCompleted}>
                    <i className="fa fa-trash-o" aria-hidden="true"></i> Clear Completed
                </button> */}
            </div>
        )
    }
}

class Tags extends React.Component {
    render() {
        let tags = this.props.tags;
        tags = tags.map(tag => {
            let dotStyle = {
                background: tag.colour
            };
            let activeStyle = {
                boxShadow: '0 0 0 2px ' + tag.colour
            };

            return (
                <button
                    key={tag.id}
                    onClick={this.props.setTag.bind(this, tag)}
                    style={tag.name === this.props.activeTag ? activeStyle : {}}>
                    <span style={dotStyle}></span>
                    {tag.name}
                </button>
            );
        });
        return (
            <div className="task-tags">
                <span></span> &nbsp;
                {tags}
                {/* <button onClick={this.props.reset.bind(this)}>Reset</button> */}
            </div>
        );
    }
}

// class Modal extends React.Component {
//     render() {
//         return (
//             <div className="modal-wrap">
//                 <div className="modal">
//                     <p>{this.props.content}</p>
//                 </div>
//             </div>
//         );
//     }
// }

const formattedTags = (tags) => {
    const f_tags = [
        {
            id: "Control Panel",
            name: 'Control Panel',
            colour: colour(true)
        },
        {
            id: "all",
            name: 'All',
            colour: colour(true)
        }
    ];

    if (!Array.isArray(tags)) return f_tags;

    for (const tag of tags) {
        f_tags.push({
            id: tag._id,
            name: tag.title,
            colour: colour(true)
        });
    }

    return f_tags;
}
const formattedTagsWithoutMain = (tags) => {
    const f_tags = [
        {
            id: "all",
            name: 'All',
            colour: colour(true)
        }
    ];

    if (!Array.isArray(tags)) return f_tags;

    for (const tag of tags) {
        f_tags.push({
            id: tag._id,
            name: tag.title,
            colour: colour(true)
        });
    }

    return f_tags;
}
const formattedTasks = (tasks) => {
    const f_tasks = [];
    for (const task of tasks) {
        f_tasks.push({
            id: task._id,
            content: task.title,
            completed: task.completed,
            tags: task.tag,
            expired: task.expired,
            expiresAt: task.expiresAt,
            todo_id: task.todo_id,
        });
    }

    return f_tasks;
}
export default class App extends React.Component {

    constructor(props) {
        super();
        const tasks = props.todos;
        const tags = props.tags;
        const all_tags = props.all_tags;

        this.createTask = props.createTodo;
        this.editTask = props.editTodo;
        this.deleteTask = props.deleteTodo;
        this.create_task = props.create_todo;
        this.edit_task = props.edit_todo;
        this.delete_task = props.delete_todo;
        this.accessToken = props.accessToken;
        this.editTodo = props.editTodoUser;
        this.edit_todo_user = props.edit_todo_user;

        this.state = {
            tasks: formattedTasks(tasks),
            tags: formattedTags(tags),
            all_tags: formattedTagsWithoutMain(all_tags),
            activeTag: 'Control Panel',
            activeFilter: 'All',
        }
    }

    componentWillMount() {
        this.setState({
            activeList: 'All',
            activeTag: 'Control Panel',
            edit: null,
            filters: [
                {
                    id: uuid(),
                    name: 'All',
                    label: 'All Tasks',
                    method: function (item) {
                        return item;
                    }
                },
                // {
                //     id: uuid(),
                //     name: 'Main',
                //     label: 'Control Panel',
                //     method: function (item) {
                //         return item;
                //     }
                // },
                {
                    id: uuid(),
                    name: 'active',
                    label: 'Active',
                    method: function (item) {
                        return item.completed === false;
                    }
                },
                {
                    id: uuid(),
                    name: 'completed',
                    label: 'Completed',
                    method: function (item) {
                        return item.completed === true;
                    }
                }
            ]
        })
    }

    // Handlers
    handleAddTask(task) {
        let tasks = this.state.tasks;
        tasks.push(task);

        const data = {
            title: task.content,
            description: '',
            icon: '',
            tags: task.tag
        }

        if (task?.tag?.length === 0) {
            message.error("Please select a tag.");
            return;
        }

        this.createTask(this.accessToken, data);

        if (this.create_task?.success) {
            this.setState({ tasks: tasks });
            localStorage.setItem('tasks', JSON.stringify(tasks));
        }
    }

    handleEditTask(task) {
        let tasks = this.state.tasks;
        const id = task.id;

        //remove id
        let target = tasks.findIndex(index => index.todo_id === id);
        tasks.splice(target, 1);

        //add new task
        tasks.push(task);

        const data = {
            title: task.content,
            description: '',
            icon: '',
            tags: task.tag
        }

        if (!!id) {
            this.editTask(this.accessToken, id, data);
        } else {
            message.error("Missing id of task.");
        }

        if (this.create_task?.success) {
            this.setState({ tasks: tasks });
            localStorage.setItem('tasks', JSON.stringify(tasks));
        }
    }

    handleRemoveTask(id) {
        this.deleteTask(this.accessToken, id);

        let tasks = this.state.tasks;
        let target = tasks.findIndex(index => index.id === id);
        tasks.splice(target, 1);
        this.setState({ tasks: tasks });
        localStorage.setItem('tasks', JSON.stringify(tasks));
    }

    showDeleteModal = (id) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you Sure you want to this delete task?</span>,
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: () => { this.handleRemoveTask(id) },
            onCancel: () => { Modal.destroyAll(); },
            confirmLoading: false,
        });
    }

    handleCheckTask(id) {
        let tasks = this.state.tasks;
        let target = tasks.findIndex(index => index.id === id);
        this.editTodo(this.accessToken, id, { completed: tasks[target].completed === true ? false : true });

        tasks[target].completed = tasks[target].completed === true ? false : true;
        this.setState({ tasks: tasks });
        localStorage.setItem('tasks', JSON.stringify(tasks));
    }

    // Setters
    setFilter(filter) {
        let activeFilter = filter.name;
        this.setState({ activeFilter: activeFilter });
    }

    setTag(tag) {
        let activeTag = tag.name;
        this.setState({ activeTag: activeTag });
    }

    reset() {
        let tasks = this.state.initial;
        this.setState({ tasks: tasks });
        localStorage.setItem('tasks', JSON.stringify(tasks));
    }

    // Getters
    getTotalCompleted() {
        let tasks = this.state.tasks;
        let completed = tasks?.filter(item => item.completed === true);
        return completed?.length ? completed.length : 0;
    }

    getTotalTasks() {
        return this.state.tasks?.length ? this.state.tasks.length : 0;
    }

    addEditTask(task) {
        this.setState({ edit: task })
    }

    getActiveList() {
        let filter = this.state.activeFilter;
        let tag = this.state.activeTag;
        let tasks = this.state.tasks;

        const all_tags = this.state.tags;

        //get current tag object
        const currentTag = all_tags?.find(item => item.name === tag);


        //Filte by Filter
        for (let i = 0, len = this.state.filters.length; i < len; i++) {
            const element = this.state.filters[i];
            if (filter === element.name) {
                tasks = tasks?.filter(function (item) {
                    return element.method(item);
                });
            }
        }

        // Filter by Tag
        if (tag === 'All' || tag === 'Control Panel') {
            return tasks;
        }
        else {
            const active_tasks = [];
            for (const task of tasks) {
                const task_tag = Array.isArray(task.tags) ? task.tags : [];
                if (task_tag.find(item => item === currentTag.id)) active_tasks.push(task);
            }
            return active_tasks;
        }
    }

    clearCompleted() {
        let tasks = this.state.tasks;
        tasks = tasks.filter(item => item.completed === false);
        this.setState({ tasks: tasks });
        localStorage.setItem('tasks', JSON.stringify(tasks));
    }

    shouldComponentUpdate(nextProps) {
        // Rendering the component only if
        // passed props value is changed

        // if (nextProps.edit !== this.state.edit) {
        //   return true;
        // } else {
        //   return false;
        // }

        return true;
    }

    render() {

        const currentTasks = this.getActiveList.call(this);
        const showEditDelete = this.state.activeTag === "Control Panel" ? true : false;

        return (
            <div className="todo-list">
                <AddTask
                    edit={this.state.edit}
                    addTask={this.handleAddTask.bind(this)}
                    updateTask={this.handleEditTask.bind(this)}
                    all_tags={this.state.all_tags}
                />

                <Tags
                    tags={this.state.tags}
                    all_tags={this.state.tags}
                    setTag={this.setTag.bind(this)}
                    activeTag={this.state.activeTag}
                    reset={this.reset.bind(this)} />

                {(!Array.isArray(currentTasks) || !currentTasks?.length) &&
                    <Alert message="congratulations!!! All Tasks are completed. " type="success" showIcon closable style={{ marginTop: '10px', marginBottom: '10px' }} />}

                {(Array.isArray(currentTasks) && currentTasks?.length > 0) &&
                    <TaskList
                        tasks={currentTasks}
                        removeTask={this.showDeleteModal.bind(this)}
                        checkTask={this.handleCheckTask.bind(this)}
                        editTask={this.addEditTask.bind(this)}
                        showEditDelete={showEditDelete}
                        tags={this.state.tags} />}

                <TaskControls
                    completed={this.getTotalCompleted.bind(this)}
                    filters={this.state.filters}
                    total={this.getTotalTasks.bind(this)}
                    activeFilter={this.state.activeFilter}
                    setFilter={this.setFilter.bind(this)}
                    clearCompleted={this.clearCompleted.bind(this)} />

            </div>
        );
    }
}