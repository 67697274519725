export const getCurrentUsersState = (state) => state.currentUser;
const getAuthState = (state) => state.auth;

export const getCurrentUserID = (state) => {
  const authState = getAuthState(state);
  return authState.login.data.userID;
};
export const getCurrentUserData = (state) => {
  const currentUserState = getCurrentUsersState(state);
  return currentUserState.data;
};
export const getCurrentUserRole = (state) => {
  const authState = getAuthState(state);
  return authState.login.data.role;
};
export const getIsCurrentUserLoading = (state) => {
  const currentUserState = getCurrentUsersState(state);
  return !!currentUserState.loading;
};

export const getIsUpdateUserInfoLoading = (state) => {
  const currentUserState = getCurrentUsersState(state);
  return currentUserState.updateUserData.loading;
};
export const getUpdateUserInfoError = (state) => {
  const currentUserState = getCurrentUsersState(state);
  return currentUserState.updateUserData.error;
};
export const getHasUpdateUserInfoError = (state) => {
  const currentUserState = getCurrentUsersState(state);
  return !!currentUserState.updateUserData.error.message;
};