import {
  all,
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import types from "redux/types";

import {
  createChallengeAPI,
  updateChallengeAPI,
  deleteChallengeAPI,
  updateChallengeStatusAPI
} from "api/challenges";

import {
  message
} from "antd";

import { createChallengeError, createChallengeSuccess, deleteChallengeError, deleteChallengeSuccess, updateChallengeError, updateChallengeStatusError, updateChallengeStatusSuccess, updateChallengeSuccess } from "redux/actions/challenges";

function* createChallengeReq(action) {
  try {
    const response = yield call(createChallengeAPI, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    yield put(createChallengeSuccess(response));
    message.success("Challenge Successfully Created.");
  } catch (error) {
    message.error('Error Creating Challenges:  ' + error?.message.toString());
    yield put(createChallengeError(error));
  }
}

function* createChallenge() {
  yield takeEvery(types.challenges.CREATE_CHALLENGE, createChallengeReq);
}

function* updateChallengeReq(action) {
  try {
    const response = yield call(updateChallengeAPI, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    yield put(updateChallengeSuccess(response));
    message.success("Challenge Successfully Updated.");
  } catch (error) {
    message.error('Error Updating Challenges:  ' + error?.message.toString());
    yield put(updateChallengeError(error));
  }
}

function* updateChallenge() {
  yield takeEvery(types.challenges.UPDATE_CHALLENGE, updateChallengeReq);
}


function* deleteChallengeReq(action) {
  try {
    const response = yield call(deleteChallengeAPI, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    yield put(deleteChallengeSuccess(response));
    message.success("Challenge Successfully Deleted.");
  } catch (error) {
    message.error('Error Deleting Challenges:  ' + error?.message.toString());
    yield put(deleteChallengeError(error));
  }
}

function* deleteChallenge() {
  yield takeEvery(types.challenges.DELETE_CHALLENGE, deleteChallengeReq);
}

function* updateChallengeStatusReq(action) {
  try {
    const response = yield call(updateChallengeStatusAPI, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    yield put(updateChallengeStatusSuccess(response));

  } catch (error) {
    message.error('Error Updating Challenges Status:  ' + error?.message.toString());
    yield put(updateChallengeStatusError(error));
  }
}

function* updateChallengeStatus() {
  yield takeEvery(types.challenges.UPDATE_CHALLENGE_STATUS, updateChallengeStatusReq);
}

//add all the saga
export default function* accountsSaga() {
  yield all([
    createChallenge(), 
    updateChallenge(), 
    deleteChallenge(), 
    updateChallengeStatus(), 
  ]);
}