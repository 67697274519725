import types from "redux/types";

export const createChallengeReq = (payload) => {
  return {
    type: types.challenges.CREATE_CHALLENGE,
    payload,
  };
};
export const createChallengeSuccess = (payload) => {
  return {
    type: types.challenges.CREATE_CHALLENGE_SUCCESS,
    payload,
  };
};
export const createChallengeError = (payload) => {
  return {
    type: types.challenges.CREATE_CHALLENGE_ERROR,
    payload,
  };
};

export const updateChallengeReq = (payload) => {
  return {
    type: types.challenges.UPDATE_CHALLENGE,
    payload,
  };
};
export const updateChallengeSuccess = (payload) => {
  return {
    type: types.challenges.UPDATE_CHALLENGE_SUCCESS,
    payload,
  };
};
export const updateChallengeError = (payload) => {
  return {
    type: types.challenges.UPDATE_CHALLENGE_ERROR,
    payload,
  };
};

export const deleteChallengeReq = (payload) => {
  return {
    type: types.challenges.DELETE_CHALLENGE,
    payload,
  };
};
export const deleteChallengeSuccess = (payload) => {
  return {
    type: types.challenges.DELETE_CHALLENGE_SUCCESS,
    payload,
  };
};
export const deleteChallengeError = (payload) => {
  return {
    type: types.challenges.DELETE_CHALLENGE_ERROR,
    payload,
  };
};

export const updateChallengeStatusReq = (payload) => {
  return {
    type: types.challenges.UPDATE_CHALLENGE_STATUS,
    payload,
  };
};
export const updateChallengeStatusSuccess = (payload) => {
  return {
    type: types.challenges.UPDATE_CHALLENGE_STATUS_SUCCESS,
    payload,
  };
};
export const updateChallengeStatusError = (payload) => {
  return {
    type: types.challenges.UPDATE_CHALLENGE_STATUS_ERROR,
    payload,
  };
};