/*eslint-disable*/
import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import paths from "routes/paths";
import './Header.scss';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Menu, Layout, Tag } from 'antd';

import action from 'redux/actions';

import TodoModal from "components/TodoModal/TodoModal";

import {
  EditOutlined,
  UserOutlined,
  LogoutOutlined,
  BulbOutlined,
  StarOutlined,
  EyeInvisibleOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
const { Sider } = Layout;
const { SubMenu } = Menu;

export default ({currentUser}) => {
  const dispatch = useDispatch();
  const themeState = useSelector(state=>state.theme);
  const todo_selector = useSelector(state=>state?.todo?.read_todo);
  const todo_user_update = useSelector(state=>state?.todo.edit_todo_user);
  const accessToken = useSelector(state=> state.auth?.login?.data?.accessToken);
  const userID = useSelector(state=> state.auth?.login?.data?.userID);


  const [mode, setMode] = useState('horizontal');
  const [theme, setTheme] = useState(themeState?.theme ? themeState?.theme : 'light');
  const { innerWidth: width } = window;
  const [collapsed, setCollapsed] = useState(width <= 992);
  const [todo, setTodo] = useState(false);
  const [notifications, setNotifications] = useState(null);

  const fullname = currentUser && currentUser.firstName && currentUser.lastName? currentUser.firstName + " " + currentUser.lastName : "User";
  const getCurrentRoute = ()=>{
    const location = useLocation();
    return location?.pathname.replace(/\//g, "") ? location?.pathname.replace(/\//g, "") : '';
  }

  const changeMode = value => {
    setMode(value ? 'vertical' : 'inline');
  };

  const changeTheme = (currentTheme) => {
    let changeTheme = '';
    if(currentTheme === "light"){
      changeTheme = 'dark';
    }else{
      changeTheme = 'light';
    }
    setTheme(changeTheme);
    dispatch(action.theme.changeTheme(changeTheme));
  };

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if(todo_selector.success){
      const todos = todo_selector?.data?.todos ? todo_selector.data.todos : 0;

      if(Array.isArray(todos)){
        const uncomplete_todo = todos.filter(td => td.completed === false);
        setNotifications(uncomplete_todo.length);
      }
    }
  },[todo_selector.success]);

  useEffect(() => {
    if(todo_user_update.success){
      dispatch(action.todo.readTodo({accessToken}));
    }
  },[todo_user_update.success]);

  const openTodoModal =()=>{
    setTodo(!todo)
  }

  return (
    <>
    <TodoModal showModal={todo} setShow={setTodo}/>
      <div className="site-header">
        <div className={theme === 'dark'? "headers-dark " : "headers"}>
          <Menu
            defaultSelectedKeys={[getCurrentRoute()]}
            mode={mode}
            theme={theme}
            className={theme === 'dark'? "header-menu-dark " : "header-menu"}
          >
          <Menu.Item key="todo" icon={<UnorderedListOutlined style={{fontSize:'18px', marginRight:'10px'}}/>} onClick={openTodoModal} disabled={todo_selector.loading}>
            {!!notifications && <Tag color='#F74D4D' className="notification-badge">{notifications}</Tag>}
          </Menu.Item>
          <Menu.Item key="theme" icon={theme === 'dark'? <BulbOutlined />: <StarOutlined /> } onClick={()=>changeTheme(theme)}>
          </Menu.Item>
            <SubMenu key="profile" icon={<UserOutlined />} title={fullname}>
              <Menu.Item key="editProfile" icon={<EditOutlined />} >
                <Link to={paths.editProfile}>
                  Edit Profile
                </Link>
              </Menu.Item>
              <Menu.Item key="changePassword" icon={<EyeInvisibleOutlined/>} >
                <Link to={paths.changePassword}>
                  Change Password
                </Link>
              </Menu.Item>
              <Menu.Item key="addException" icon={<LogoutOutlined />} danger>
                <Link to={paths.logout} >
                  Logout
                </Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      </div>
      <br/>
    </>
  );
};
