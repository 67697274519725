
export const readTodo = (state) => {
  return state.todo.read_todo;
};
export const createTodo = (state) => {
  return state.todo.create_todo;
};
export const editTodo = (state) => {
  return state.todo.edit_todo;
};
export const deleteTodo = (state) => {
  return state.todo.delete_todo;
};
export const editTodoUser = (state) => {
  return state.todo.edit_todo_user;
};

export const createTodoComment = (state) => {
  return state.todo.create_todo_comment;
};
export const readTodoComment = (state) => {
  return state.todo.read_todo_comment;
};
export const editTodoComment = (state) => {
  return state.todo.edit_todo_comment;
};
export const deleteTodoComment = (state) => {
  return state.todo.delete_todo_comment;
};


export const readTrades = (state) => {
  return state.todo.read_trades;
};
//name changed
export const readTLog = (state) => {
  return state.todo.read_trade_comment;
};
export const editTLog = (state) => {
  return state.todo.edit_trade_comment;
};
export const deleteTLog = (state) => {
  return state.todo.delete_trade_comment;
};

export const LoginLog = (state) => {
  return state.todo.l_log;
};

export const Uncommented = (state) => {
  return state.todo.uncommented;
};

export const Summary = (state) => {
  return state.todo.summary;
};