import types from "redux/types";

export const changeTheme = (payload) => {
    return {
        type: types.theme.CURRENT_THEME,
        payload,
    };
};

export const changeSlider = (payload) => {
    return {
        type: types.theme.CURRENT_SLIDER_STATUS,
        payload,
    };
};