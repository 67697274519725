import types from "redux/types";

export const accountInfoInit = (payload) => {
  return {
    type: types.dashboard.ACCOUNTS_INFO,
    payload,
  };
};
export const accountInfoSuccess = (payload) => {
  return {
    type: types.dashboard.ACCOUNTS_INFO_SUCCESS,
    payload,
  };
};
export const accountInfoError = (payload) => {
  return {
    type: types.dashboard.ACCOUNTS_INFO_ERROR,
    payload,
  };
};


export const tradesInfoInit = (payload) => {
  return {
    type: types.dashboard.TRADES_INFO,
    payload,
  };
};
export const tradesInfoSuccess = (payload) => {
  return {
    type: types.dashboard.TRADES_INFO_SUCCESS,
    payload,
  };
};
export const tradesInfoError = (payload) => {
  return {
    type: types.dashboard.TRADES_INFO_ERROR,
    payload,
  };
};

//open trades
export const tradesOpenInit = (payload) => {
  return {
    type: types.dashboard.TRADES_OPEN,
    payload,
  };
};
export const tradesOpenSuccess = (payload) => {
  return {
    type: types.dashboard.TRADES_OPEN_SUCCESS,
    payload,
  };
};
export const tradesOpenError = (payload) => {
  return {
    type: types.dashboard.TRADES_OPEN_ERROR,
    payload,
  };
};