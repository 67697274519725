import { get, post, remove } from "utils/request";


export async function getTrades(payload) {
  const {accessToken, account_number} = payload;
  console.log("getTrades: ", {payload});
  return get("/tradelogs/trades/"+account_number, accessToken);
}

export async function readTodoComment(payload) {

  const {accessToken, ticket_number} = payload;
  
  return get("/tradelogs/trade_logs/"+ticket_number, accessToken);
}

export async function editTodoComment(payload) {
  const {accessToken, data} = payload;
  return post("/tradelogs/trade_logs", data, accessToken);
}

export async function deleteTodoComment(payload) {
  const {accessToken, id} = payload;
  return remove("/tradelogs/trade_logs/"+id, accessToken);
}

//not needed yet
export async function createTodoComment(payload) {
  const {accessToken, data} = payload;
  return post("/tradelogs/t_comment/", data, accessToken);
}

//login
export async function loginLogs(payload) {

  const {accessToken, user_id} = payload;
  
  return get("/tradelogs/login_logs/"+user_id, accessToken);
}

export async function uncommented(payload) {

  const {accessToken, account_number} = payload;
  
  return get("/tradelogs/uncommented/"+account_number, accessToken);
}
export async function summary(payload) {

  const {accessToken, account_number} = payload;
  
  return get("/tradelogs/summary/"+account_number, accessToken);
}