import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import reducers from "redux/reducers";
import sagas from "redux/sagas";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "currentUser"],
};

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = (store) => (next) => (action) => {
  next(action);

  // TODO: Only log in dev env.
  const { type, payload } = action;
  if (type) console.log(`Action: %c ${type}`, "background: #222; color: yellow;");
  if (payload) console.log("Payload: ", payload);
};

const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(sagaMiddleware, logger)));

sagaMiddleware.run(sagas);

const persistor = persistStore(store);
export { store, persistor };