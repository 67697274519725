import { get, post, remove } from "utils/request";

export async function update(payload) {
  const {accessToken, data} = payload;
  return post("/rules", data, accessToken);
}

export async function read_demo(payload) {
  const {accessToken} = payload;
  return get("/rules/demo", accessToken);
}

export async function read_funded(payload) {
  const {accessToken} = payload;
  return get("/rules/funded", accessToken);
}