export default {

  READ_CLIENTS: "/protfolio/clients/request",
  READ_CLIENTS_SUCCESS: "/protfolio/clients/success",
  READ_CLIENTS_ERROR: "/protfolio/clients/error",

  READ_SUMMARY: "/protfolio/summary/request",
  READ_SUMMARY_SUCCESS: "/protfolio/summary/success",
  READ_SUMMARY_ERROR: "/protfolio/summary/error",

  READ_REPORT: "/client/report/request",
  READ_REPORT_SUCCESS: "/client/report/success",
  READ_REPORT_ERROR: "/client/report/error",

  READ_CLIENT: "/protfolio/client/request",
  READ_CLIENT_SUCCESS: "/protfolio/client/success",
  READ_CLIENT_ERROR: "/protfolio/client/error",

  VERIFY_CLIENT: "/protfolio/verify_client/request",
  VERIFY_CLIENT_SUCCESS: "/protfolio/verify_client/success",
  VERIFY_CLIENT_ERROR: "/protfolio/verify_client/error",

  ACTIVATE_CLIENT: "/protfolio/activate_client/request",
  ACTIVATE_CLIENT_SUCCESS: "/protfolio/activate_client/success",
  ACTIVATE_CLIENT_ERROR: "/protfolio/activate_client/error",

  CREATE_CLIENTS: "/protfolio/create/request",
  CREATE_CLIENTS_SUCCESS: "/protfolio/create/success",
  CREATE_CLIENTS_ERROR: "/protfolio/create/error",

  EDIT_CLIENTS: "/protfolio/edit/request",
  EDIT_CLIENTS_SUCCESS: "/protfolio/edit/success",
  EDIT_CLIENTS_ERROR: "/protfolio/edit/error",

  DELETE_CLIENTS: "/protfolio/delete/request",
  DELETE_CLIENTS_SUCCESS: "/protfolio/delete/success",
  DELETE_CLIENTS_ERROR: "/protfolio/delete/error",


  CREATE_INTEREST: "/interest/create/request",
  CREATE_INTEREST_SUCCESS: "/interest/create/success",
  CREATE_INTEREST_ERROR: "/interest/create/error",

  EDIT_INTEREST: "/interest/edit/request",
  EDIT_INTEREST_SUCCESS: "/interest/edit/success",
  EDIT_INTEREST_ERROR: "/interest/edit/error",

  DELETE_INTEREST: "/interest/delete/request",
  DELETE_INTEREST_SUCCESS: "/interest/delete/success",
  DELETE_INTEREST_ERROR: "/interest/delete/error",


  CREATE_INVEST: "/invest/create/request",
  CREATE_INVEST_SUCCESS: "/invest/create/success",
  CREATE_INVEST_ERROR: "/invest/create/error",

  EDIT_INVEST: "/invest/edit/request",
  EDIT_INVEST_SUCCESS: "/invest/edit/success",
  EDIT_INVEST_ERROR: "/invest/edit/error",

  DELETE_INVEST: "/invest/delete/request",
  DELETE_INVEST_SUCCESS: "/invest/delete/success",
  DELETE_INVEST_ERROR: "/invest/delete/error",

  SEND_REPORT: "/report/send/request",
  SEND_REPORT_SUCCESS: "/report/send/success",
  SEND_REPORT_ERROR: "/report/send/error",


  CREATE_WP: "/weekly_profit/create/request",
  CREATE_WP_SUCCESS: "/weekly_profit/create/success",
  CREATE_WP_ERROR: "/weekly_profit/create/error",

  READ_WP: "/weekly_profit/read/request",
  READ_WP_SUCCESS: "/weekly_profit/read/success",
  READ_WP_ERROR: "/weekly_profit/read/error",

  UPDATE_WP: "/weekly_profit/update/request",
  UPDATE_WP_SUCCESS: "/weekly_profit/update/success",
  UPDATE_WP_ERROR: "/weekly_profit/update/error",

  DELETE_WP: "/weekly_profit/delete/request",
  DELETE_WP_SUCCESS: "/weekly_profit/delete/success",
  DELETE_WP_ERROR: "/weekly_profit/delete/error",
};