import types from "redux/types";



const initialState = {
  loading: false,
  success: false,
  data: [],
  error: []
}

const initialUserState = {
  createChallenge: {
    ...initialState,
  },
  updateChallenge: {
    ...initialState,
  },
  deleteChallenge: {
    ...initialState,
  },
  updateChallengeStatus: {
    ...initialState,
  },
};

export default (state = initialUserState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {

    //read Statisctics
    case types.challenges.CREATE_CHALLENGE:
      return {
        ...state,
        createChallenge: {
          ...initialState,
          loading: true,
        }
      };
    case types.challenges.CREATE_CHALLENGE_SUCCESS:
      return {
        ...state,
        createChallenge: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.challenges.CREATE_CHALLENGE_ERROR:
      return {
        ...state,
        createChallenge: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };

      //read Statisctics
    case types.challenges.UPDATE_CHALLENGE:
      return {
        ...state,
        updateChallenge: {
          ...initialState,
          loading: true,
        }
      };
    case types.challenges.UPDATE_CHALLENGE_SUCCESS:
      return {
        ...state,
        updateChallenge: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.challenges.UPDATE_CHALLENGE_ERROR:
      return {
        ...state,
        updateChallenge: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };

      //read Statisctics
    case types.challenges.DELETE_CHALLENGE:
      return {
        ...state,
        deleteChallenge: {
          ...initialState,
          loading: true,
        }
      };
    case types.challenges.DELETE_CHALLENGE_SUCCESS:
      return {
        ...state,
        deleteChallenge: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.challenges.DELETE_CHALLENGE_ERROR:
      return {
        ...state,
        deleteChallenge: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };

      //read Statisctics
    case types.challenges.UPDATE_CHALLENGE_STATUS:
      return {
        ...state,
        updateChallengeStatus: {
          ...initialState,
          loading: true,
        }
      };
    case types.challenges.UPDATE_CHALLENGE_STATUS_SUCCESS:
      return {
        ...state,
        updateChallengeStatus: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.challenges.UPDATE_CHALLENGE_STATUS_ERROR:
      return {
        ...state,
        updateChallengeStatus: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };

    default:
      return state;
  }
};