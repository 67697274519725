
export const getAllUsers = (state) => {
  return state.cUsers.cusers;
};

export const verifyUser = (state) => {
  return state.cUsers.verifycUser;
};
export const updateCUser = (state) => {
  return state.cUsers.updateCUser;
};

export const deleteUser = (state) => {
  return state.cUsers.deletecUser;
};

export const getPendingInvests = (state) => {
  return state.cUsers.pInvests;
};

export const verifyPendingInvest = (state) => {
  return state.cUsers.verifypInvests;
};

export const deletePendingInvest = (state) => {
  return state.cUsers.deletepInvests;
};
