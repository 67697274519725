import { get, post, remove } from "utils/request";

export async function createAccountReq(payload) {
  const {accessToken, data} = payload;
  return post("/accounts", data, accessToken);
}

export async function updateAccountReq(payload) {
  const {accessToken, data, id} = payload;
  console.log({data}, {id});
  return post(`/accounts/${id}`, data, accessToken);
}

export async function readAccountReq(payload) {
  const {accessToken, id} = payload;
  return get(`/accounts/${id}`, accessToken);
}

export async function deleteAccountReq(payload) {
  const {accessToken, id} = payload;
  return remove(`/accounts/${id}`, accessToken);
}

export async function readAllAccountsReq(payload) {
  const {accessToken} = payload;
  return get(`/accounts`, accessToken);
}

export async function readAllUsers(payload) {
  const {accessToken} = payload;
  return get(`/accounts/users`, accessToken);
}

export async function readBrokers(payload) {
  const {accessToken} = payload;
  return get(`/accounts/brokers`, accessToken);
}

export async function runAccount(payload) {
  const {accessToken, data} = payload;
  return post(`/accounts/runAccount`, data, accessToken);
}

export async function killAccount(payload) {
  const {accessToken, data} = payload;
  return post(`/accounts/stopAccount`, data, accessToken);
}