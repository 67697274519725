import { get, post, remove } from "utils/request";

export async function readAccountStatisticsReq(payload) {
  const {accessToken, account_number} = payload;
  return get(`/accounts/statistics/${account_number}`, accessToken);
}

export async function readFundedAccountStatisticsReq(payload) {
  const {accessToken, account_number} = payload;
  return get(`/funded-accounts/statistics/${account_number}`, accessToken);
}

export async function readAccountStatisticsDateReq(payload) {
  const {account_number, accessToken, data} = payload;
  return post(`/accounts/statisticsByDate/${account_number}`, data, accessToken);
}

export async function readAccountPropsReq(payload) {
  const {accessToken} = payload;
  return get('/accounts/statistics', accessToken);
}