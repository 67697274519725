import { get, post, remove } from "utils/request";

export async function accountInfoReq(payload) {
  const {accessToken} = payload;
  return get(`/info/accounts`, accessToken);
}

export async function tradeInfoReq(payload) {
  const {accessToken, data} = payload;
  return post(`/info/trades`, data, accessToken);
}

export async function tradeOpenReq(payload) {
  const {accessToken} = payload;
  return get(`/info/trades/open`, accessToken);
}