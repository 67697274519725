import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import PageLoader from 'components/PageLoader/PageLoader';

import Paths from './paths';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

/**Auth Paths */
const Login = lazy(() => import('pages/auth/Login/login'));
const Signup = lazy(() => import('pages/auth/Signup/signup'));
const Logout = lazy(() => import('pages/auth/Logout/Logout'));

/*Email */
const ConfirmEmail = lazy(() => import('pages/auth/ConfirmEmail/ConfirmEmail'));
const EmailVerification = lazy(() =>import('pages/auth/EmailVerification/EmailVerification'));
const ForgotPassword = lazy(() =>import('pages/auth/ForgotPassword/ForgotPassword'));
const ResetPasswordLinkSent = lazy(() =>import('pages/auth/ResetPasswordLinkSent/ResetPasswordLinkSent'));
const ResetPassword = lazy(() =>import('pages/auth/ResetPassword/ResetPassword'));
const ResetPasswordSuccess = lazy(() =>import('pages/auth/ResetPasswordSuccess/ResetPasswordSuccess'));


/** CMS Paths */
const Dashboard = lazy(() => import('pages/Dashboard/Home/Dashboard'));
const EditProfile = lazy(() => import('pages/Profile/editProfile'));
const ChangePassword = lazy(() => import('pages/Profile/ChangePassword/changePassword'));
const Settings = lazy(() => import('pages/Dashboard/Settings/Settings'));
const RulesRead = lazy(() => import('pages/Dashboard/Settings/Rules/read'));
const RulesCreate = lazy(() => import('pages/Dashboard/Settings/Rules/create'));
const Users = lazy(() => import('pages/Dashboard/Users/Users'));
const CUsers = lazy(() => import('pages/Dashboard/cUsers/cUsers'));

//demo-account
const Accounts = lazy(() => import('pages/Dashboard/DemoAccounts/read'));
const AddAccount = lazy(() => import('pages/Dashboard/DemoAccounts/create'));
const EditAccount = lazy(() => import('pages/Dashboard/DemoAccounts/update'));
const AccountView = lazy(() => import('pages/Dashboard/DemoAccounts/overview'));


//funded-account
const FundedAccounts = lazy(() => import('pages/Dashboard/FundedAccounts/read'));
const FundedAddAccount = lazy(() => import('pages/Dashboard/FundedAccounts/create'));
const FundedEditAccount = lazy(() => import('pages/Dashboard/FundedAccounts/update'));
const FundedAccountView = lazy(() => import('pages/Dashboard/FundedAccounts/overview'));

//challenge
const AddChallenges = lazy(() => import('pages/Dashboard/Challenges/create'));
const Challenges = lazy(() => import('pages/Dashboard/Challenges/read'));
const EditChallenges = lazy(() => import('pages/Dashboard/Challenges/create'));

//investors
const Investors = lazy(() => import('pages/Dashboard/Investors/read'));
const AddInvestors = lazy(() => import('pages/Dashboard/Investors/create'));
const EditInvestors = lazy(() => import('pages/Dashboard/Investors/create'));
const EditDInvestors = lazy(() => import('pages/Dashboard/Investors/edit_dummy'));

//portfolio
const Portfolio = lazy(() => import('pages/Dashboard/portfolio/read'));
const AddClients = lazy(() => import('pages/Dashboard/portfolio/create'));
const EditClients = lazy(() => import('pages/Dashboard/portfolio/update'));
const Report = lazy(() => import('pages/Dashboard/portfolio/report'));

const Home = lazy(() => import('pages/Dashboard/pmw'));
const BankBalance = lazy(() => import('pages/Dashboard/Settings/bank_balance'));
const WeeklyProfit = lazy(() => import('pages/Dashboard/Settings/weekly_profit'));

const TradeLogs = lazy(() => import('pages/Dashboard/tradelogs/read'));
const TradeLog = lazy(() => import('pages/Dashboard/tradelogs/singleLog'));


const AccountPsyView = lazy(() => import('pages/Dashboard/Psy/overview'));

const Routes = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Suspense fallback={<PageLoader />}>
          {/* Index Routes */}
          <PublicRoute exact path={Paths.index} component={Login} />
          <PublicRoute exact path={Paths.signup} component={Signup} />
          <PublicRoute exact path={Paths.logout} component={Logout} />
          
          <PublicRoute exact path={Paths.confirmEmail} component={ConfirmEmail} />
          <PublicRoute exact path={Paths.emailVerification} component={EmailVerification} />
          <PublicRoute exact path={Paths.forgotPassword} component={ForgotPassword} />
          <PublicRoute exact path={Paths.resetPasswordLinkSent} component={ResetPasswordLinkSent} />
          <PublicRoute exact path={Paths.resetPassword} component={ResetPassword} />
          <PublicRoute exact path={Paths.resetPasswordSuccess} component={ResetPasswordSuccess} />
          {/* Private Routes */}
          
          {/* Every User Right */}
          <PrivateRoute exact path={Paths.editProfile} component={EditProfile} />
          <PrivateRoute exact path={Paths.changePassword} component={ChangePassword} />
          
          {/* Strickly Admin */}
          <PrivateRoute exact path={Paths.dashboard} component={Dashboard} />
          <PrivateRoute exact path={Paths.users} component={Users} />
          <PrivateRoute exact path={Paths.cUsers} component={CUsers} />
          <PrivateRoute exact path={Paths.settings} component={Settings} />
          <PrivateRoute exact path={Paths.readRules} component={RulesRead} />
          <PrivateRoute exact path={Paths.createRules} component={RulesCreate} />
          
          {/* Privilaged Users */}
          <PrivateRoute exact path={Paths.accounts} component={Accounts} />
          <PrivateRoute exact path={Paths.accountView} component={AccountView} />
          <PrivateRoute exact path={Paths.addAccount} component={AddAccount} />
          <PrivateRoute exact path={Paths.editAccount} component={EditAccount} />
          
          {/* Privilaged Users */}
          <PrivateRoute exact path={Paths.challenges} component={Challenges} />
          <PrivateRoute exact path={Paths.addChallenges} component={AddChallenges} />
          <PrivateRoute exact path={Paths.editChallenge} component={EditChallenges} />
          
          {/* Privilaged Users */}
          <PrivateRoute exact path={Paths.fundedAccounts} component={FundedAccounts} />
          <PrivateRoute exact path={Paths.addFundedAccount} component={FundedAddAccount} />
          <PrivateRoute exact path={Paths.editFundedAccount} component={FundedEditAccount} />
          <PrivateRoute exact path={Paths.fundedAccountDetails} component={FundedAccountView} />

          {/* Investors Accounts */}
          <PrivateRoute exact path={Paths.investors} component={Investors} />
          <PrivateRoute exact path={Paths.addInvestors} component={AddInvestors} />
          <PrivateRoute exact path={Paths.editInvestors} component={EditInvestors} />
          <PrivateRoute exact path={Paths.editDInvestors} component={EditDInvestors} />
          
          {/* Portfolio */}
          <PrivateRoute exact path={Paths.portfolio} component={Portfolio} />
          <PrivateRoute exact path={Paths.addClients} component={AddClients} />
          <PrivateRoute exact path={Paths.editClients} component={EditClients} />
          <PrivateRoute exact path={Paths.report} component={Report} />

          {/*pmw users */}
          <PrivateRoute exact path={Paths.bank_balance} component={BankBalance} />
          <PrivateRoute exact path={Paths.weekly_profit} component={WeeklyProfit} />
          <PrivateRoute exact path={Paths.home} component={Home} />

          {/*tradelogs*/}
          <PrivateRoute exact path={Paths.tradelogs} component={TradeLogs} />
          <PrivateRoute exact path={Paths.tradelog} component={TradeLog} />

          
          <PrivateRoute exact path={Paths.accountPsyView} component={AccountPsyView} />

        </Suspense>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
