import types from "redux/types";



const initialState = {
  loading: false,
  success: false,
  data: [],
  error: []
}

const initialUserState = {
  users: {
    ...initialState,
  },
  editUser:{
    ...initialState,
  },
  deleteUser:{
    ...initialState
  }
};

export default (state = initialUserState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case types.users.GET_ALL_USERS:
      return {
        ...state,
        users: {
          ...initialState,
          loading: true,
        }
      };
    case types.users.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.users.GET_ALL_USERS_ERROR:
      return {
        ...state,
        users: {
          ...initialState,
          success: false,
          error: payload?.error,
        }
      };
    case types.users.GET_ALL_USERS_RESET:
      return {
        ...state,
        users: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.users.DELETE_USER:
      return {
        ...state,
        deleteUser: {
          ...initialState,
          loading: true,
        }
      };
    case types.users.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUser: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.users.DELETE_USER_ERROR:
      return {
        ...state,
        deleteUser: {
          ...initialState,
          success: false,
          error: payload?.error,
        }
      };
    case types.users.EDIT_USER:
      return {
        ...state,
        editUser: {
          ...initialState,
          loading: true,
        }
      };
    case types.users.EDIT_USER_SUCCESS:
      return {
        ...state,
        editUser: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.users.EDIT_USER_ERROR:
      return {
        ...state,
        editUser: {
          ...initialState,
          success: false,
          error: payload?.error,
        }
      };
    default:
      return state;
  }
};