import { get, post, remove } from "utils/request";

export async function readTodo(payload) {
  const {accessToken} = payload;
  return get("/todo/todo", accessToken);
}

export async function createTodo(payload) {
  const {accessToken, data} = payload;
  return post("/todo/todo", data, accessToken);
}
export async function editTodo(payload) {
  const {accessToken, id, data} = payload;
  return post("/todo/todo/"+id, data, accessToken);
}
export async function deleteTodo(payload) {
  const {accessToken, id} = payload;
  return remove("/todo/todo/"+id, accessToken);
}
export async function editTodoUser(payload) {
  const {accessToken, id, data} = payload;
  return post("/todo/todo-user/"+id, data, accessToken);
}
export async function readTodoComment(payload) {
  const {accessToken} = payload;
  return get("/todo/t_comment", accessToken);
}

export async function editTodoComment(payload) {
  const {accessToken, id, data} = payload;
  return post("/todo/t_comment/"+id, data, accessToken);
}

export async function deleteTodoComment(payload) {
  const {accessToken, id} = payload;
  return remove("/todo/t_comment/"+id, accessToken);
}

export async function createTodoComment(payload) {
  const {accessToken, data} = payload;
  return post("/todo/t_comment/", data, accessToken);
}