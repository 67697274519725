import types from "redux/types";



const initialState = {
  loading: false,
  success: false,
  data: [],
  error: []
}

const initialUserState = {
  read: {
    ...initialState,
  },
};

export default (state = initialUserState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {

    
    //read
    case types.fundedAccounts.READ_FUNDED_ACCOUNTS:
      return {
        ...state,
        read: {
          ...initialState,
          loading: true,
        }
      };
    case types.fundedAccounts.READ_FUNDED_ACCOUNTS_SUCCESS:
      return {
        ...state,
        read: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.fundedAccounts.READ_FUNDED_ACCOUNTS_ERROR:
      return {
        ...state,
        read: {
          ...initialState,
          error: payload?.data,
        }
      };

    default:
      return state;
  }
};