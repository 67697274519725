
export const accountInfo = (state) => {
  return state.dashboard.accountInfo;
};

export const tradeInfo = (state) => {
  return state.dashboard.tradeInfo;
};

export const tradeOpen = (state) => {
  return state.dashboard.tradeOpen;
};