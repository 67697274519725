import {
  all,
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import types from "redux/types";
import {
  readStatisticsSuccess,
  readStatisticsError,
  readStatisticsDateSuccess,
  readStatisticsDateError,
  readPropsSuccess,
  readPropsError,
  readFundedStatisticsSuccess,
  readFundedStatisticsError
} from "redux/actions/statistics";

import {
  readAccountStatisticsReq,
  readAccountPropsReq,
  readAccountStatisticsDateReq,
  readFundedAccountStatisticsReq,
} from "api/statistics";

import {
  message
} from "antd";

function* readStatisticsReq(action) {
  try {
    const response = yield call(readAccountStatisticsReq, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    yield put(readStatisticsSuccess(response));

  } catch (error) {
    message.error('Error Reading Account Statistics. ' + error?.message.toString());
    yield put(readStatisticsError(error));
  }
}

function* readStatistics() {
  yield takeEvery(types.statistics.READ_STATISTICS, readStatisticsReq);
}

/**Statistics for funded account */
function* readFundedStatisticsReq(action) {
  try {
    const response = yield call(readFundedAccountStatisticsReq, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    yield put(readFundedStatisticsSuccess(response));

  } catch (error) {
    message.error('Error Reading Account Statistics. ' + error?.message.toString());
    yield put(readFundedStatisticsError(error));
  }
}

function* readFundedStatistics() {
  yield takeEvery(types.statistics.READ_FUNDED_STATISTICS, readFundedStatisticsReq);
}


/*Statistics by date */
function* readStatisticsDateReq(action) {
  try {
    const response = yield call(readAccountStatisticsDateReq, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    yield put(readStatisticsDateSuccess(response));

  } catch (error) {
    message.error('Error Reading Account Statistics Dates. ' + error?.message.toString());
    yield put(readStatisticsDateError(error));
  }
}

function* readStatisticsDate() {
  yield takeEvery(types.statistics.READ_STATISTICS_DATE, readStatisticsDateReq);
}


function* readPropsReq(action) {
  try {
    const response = yield call(readAccountPropsReq, action.payload);
    const {
      error
    } = response;
    if (error) throw error;

    yield put(readPropsSuccess(response));

  } catch (error) {
    message.error('Error Reading Account Props. ' + error?.message.toString());
    yield put(readPropsError(error));
  }
}

function* readProps() {
  yield takeEvery(types.statistics.READ_PROPS, readPropsReq);
}



//add all the saga
export default function* accountsSaga() {
  yield all([
    readStatistics(), 
    readFundedStatistics(), 
    readStatisticsDate(),
    readProps(), 
  ]);
}