export default {
  LOGIN_REQUEST: "/auth/login/request",
  LOGIN_SUCCESS: "/auth/login/success",
  LOGIN_ERROR: "/auth/login/error",
  LOGOUT_REQUEST: "/auth/logout/request",
  LOGOUT_SUCCESS: "/auth/logout/success",
  LOGOUT_ERROR: "/auth/logout/error",
  SIGNUP_REQUEST: "/auth/signup/request",
  SIGNUP_SUCCESS: "/auth/signup/success",
  SIGNUP_ERROR: "/auth/signup/error",
  EMAIL_VERIFY_REQUEST: "auth/email-verify/request",
  EMAIL_VERIFY_SUCCESS: "auth/email-verify/success",
  EMAIL_VERIFY_ERROR: "auth/email-verify/error",

  FORGOT_PASSWORD_REQUEST: "/forgot/password/request",
  FORGOT_PASSWORD_SUCCESS: "/forgot/password/success",
  FORGOT_PASSWORD_ERROR: "/forgot/password/error",
  FORGOT_PASSWORD_RESET: "/forgot/password/reset",

  RESET_PASSWORD_REQUEST: "/reset/password/request",
  RESET_PASSWORD_SUCCESS: "/reset/password/success",
  RESET_PASSWORD_ERROR: "/reset/password/error",
  RESET_PASSWORD_RESET: "/reset/password/reset"
};
