export default {

  ACCOUNTS_INFO: "/accounts/info/request",
  ACCOUNTS_INFO_SUCCESS: "/accounts/info/success",
  ACCOUNTS_INFO_ERROR: "/accounts/info/error",

  TRADES_INFO: "/trades/info/request",
  TRADES_INFO_SUCCESS: "/trades/info/success",
  TRADES_INFO_ERROR: "/trades/info/error",

  TRADES_OPEN: "/trades/open/request",
  TRADES_OPEN_SUCCESS: "/trades/open/success",
  TRADES_OPEN_ERROR: "/trades/open/error",
};