import types from "redux/types";

export const loginRequest = (payload) => {
  return {
    type: types.auth.LOGIN_REQUEST,
    payload,
  };
};
export const loginSuccess = (payload) => {
  return {
    type: types.auth.LOGIN_SUCCESS,
    payload,
  };
};
export const loginError = (error) => {
  return {
    type: types.auth.LOGIN_ERROR,
    payload: error,
  };
};

export const signupRequest = (payload) => {
  return {
    type: types.auth.SIGNUP_REQUEST,
    payload,
  };
};
export const signupSuccess = (payload) => {
  return {
    type: types.auth.SIGNUP_SUCCESS,
    payload,
  };
};
export const signupError = (error) => {
  return {
    type: types.auth.SIGNUP_ERROR,
    payload: error,
  };
};

export const logoutRequest = (payload) => {
  return {
    type: types.auth.LOGOUT_REQUEST,
    payload,
  };
};
export const logoutSuccess = (payload) => {
  return {
    type: types.auth.LOGOUT_SUCCESS,
    payload,
  };
};
export const logoutError = (payload) => {
  return {
    type: types.auth.SIGNUP_ERROR,
    payload,
  };
};

export const emailVerifyRequest = (payload) => {
  return {
    type: types.auth.EMAIL_VERIFY_REQUEST,
    payload,
  };
};
export const emailVerifySuccess = (payload) => {
  return {
    type: types.auth.EMAIL_VERIFY_SUCCESS,
    payload,
  };
};
export const emailVerifyError = (payload) => {
  return {
    type: types.auth.EMAIL_VERIFY_ERROR,
    payload,
  };
};


export const forgotPasswordRequest = (payload) => {
  return {
    type: types.auth.FORGOT_PASSWORD_REQUEST,
    payload,
  };
};

export const forgotPasswordSuccess = (payload) => {
  return {
    type: types.auth.FORGOT_PASSWORD_SUCCESS,
    payload,
  };
};

export const forgotPasswordError = (error) => {
  return {
    type: types.auth.FORGOT_PASSWORD_ERROR,
    payload: error,
  };
};

export const forgotPasswordReset = () => {
  return {
    type: types.auth.FORGOT_PASSWORD_RESET
  };
};

export const resetPasswordRequest = (payload) => {
  return {
    type: types.auth.RESET_PASSWORD_REQUEST,
    payload,
  };
};

export const resetPasswordSuccess = (payload) => {
  return {
    type: types.auth.RESET_PASSWORD_SUCCESS,
    payload,
  };
};

export const resetPasswordError = (error) => {
  return {
    type: types.auth.RESET_PASSWORD_ERROR,
    payload: error,
  };
};

export const resetPasswordReset = () => {
  return {
    type: types.auth.RESET_PASSWORD_RESET
  };
};