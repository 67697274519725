import config from "config/config";

const headers = {
  "Content-type": "application/json; charset=UTF-8",
};

const authHeaders = (accessToken) => ({
  ...headers,
  Authorization: `Bearer ${accessToken}`,
});

export const post = (url, body, accessToken = null) => {
  const apiUrl = config.apiBase + url;

  return fetch(apiUrl, {
    method: "post",
    headers: !accessToken ? headers : authHeaders(accessToken),
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      throw new Error("Server Error");
    });
};

export const get = (url, accessToken = null) => {
  const apiUrl = config.apiBase + url;

  return fetch(apiUrl, {
    method: "get",
    headers: !accessToken ? headers : authHeaders(accessToken),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      throw new Error("Server Error: "+err.toString());
    });
};

export const remove = (url, accessToken = null) => {
  const apiUrl = config.apiBase + url;

  return fetch(apiUrl, {
    method: "delete",
    headers: !accessToken ? headers : authHeaders(accessToken),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      throw new Error("Server Error: "+err.toString());
    });
};



export const postFile = (url, formData, accessToken = null) => {
  const apiUrl = config.apiBase + url;

  return fetch(apiUrl, {
    method: "post",
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    body: formData,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      throw new Error("Server Error");
    });
};