import types from "redux/types";

const initialAuthState = {
  login: {
    loading: false,
    success: false,
    data: [],
    error: [],
  },
  signup: {
    loading: false,
    success: false,
    data: [],
    error: [],
  },
  logout: {
    loading: false,
    success: false,
    data: [],
    error: [],
  },
  emailVerify: {
    loading: false,
    success: false,
    data: [],
    error: [],
  },
  forgotPassword: {
    loading: false,
    success: false,
    data: null,
    error: null,
  },
  resetPassword: {
    loading: false,
    success: false,
    data: null,
    error: null,
  }
};

export default (state = initialAuthState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.auth.LOGIN_REQUEST:
      return {
        ...state,
        login: {
          ...initialAuthState.login,
          loading: true,
        },
        signup: initialAuthState.signup,
      };
    case types.auth.LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          success: true,
          data: payload,
          error: initialAuthState.login.error,
        },
      };
    case types.auth.LOGIN_ERROR:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          success: false,
          error: payload,
        },
      };
    case types.auth.LOGOUT_REQUEST:
      return {
        ...state,
        logout: {
          ...state.logout,
          loading: true,
        },
      };
    case types.auth.LOGOUT_SUCCESS:
      return {
        ...initialAuthState,
      };
    case types.auth.LOGOUT_ERROR:
      return {
        ...state,
        logout: {
          ...state.logout,
          loading: false,
          success: false,
          data: [],
          error: [],
        },
      };
    case types.auth.SIGNUP_REQUEST:
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: true,
        },
      };
    case types.auth.SIGNUP_SUCCESS:
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: false,
          success: true,
          data: payload,
        },
      };
    case types.auth.SIGNUP_ERROR:
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: false,
          success: false,
          error: payload,
        },
      };

    case types.auth.EMAIL_VERIFY_REQUEST:
      return {
        ...state,
        emailVerify: {
          ...state.emailVerify,
          loading: true,
        },
      };
    case types.auth.EMAIL_VERIFY_SUCCESS:
      return {
        ...state,
        emailVerify: {
          ...state.emailVerify,
          loading: false,
          success: true,
          data: payload,
          error: initialAuthState.emailVerify.error,
        },
      };
    case types.auth.EMAIL_VERIFY_ERROR:
      return {
        ...state,
        emailVerify: {
          ...state.emailVerify,
          loading: false,
          success: false,
          data: initialAuthState.emailVerify.data,
          error: payload,
        },
      };

    case types.auth.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPassword: {...state.forgotPassword, loading: true}
      };
    case types.auth.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {...state.forgotPassword, loading: false, success: true, data: payload},
      };
    case types.auth.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPassword: {...state.forgotPassword, loading: false, success: false, error: payload}
      };
    case types.auth.FORGOT_PASSWORD_RESET:
      return {
        ...state,
        forgotPassword: {...state.forgotPassword, loading: false, success: false, data:null, error: null}
      };
    
    case types.auth.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPassword: {...state.resetPassword, loading: true}
      };
    case types.auth.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {...state.resetPassword, loading: false, success: true, data: payload},
      };
    case types.auth.RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPassword: {...state.resetPassword, loading: false, success: false, error: payload}
      };
    case types.auth.RESET_PASSWORD_RESET:
      return {
        ...state,
        resetPassword: {...state.resetPassword, loading: false, success: false, data:null, error: null}
      };

    default:
      return state;
  }
};
