import { all, call, put, takeEvery} from "redux-saga/effects";

import types from "redux/types";
import {
  getAllCUsersError,
  getAllCUsersSuccess,

  getAllPInvestError,
  getAllPInvestSuccess,

  verifyCUsersError,
  verifyCUsersSuccess,

  verifyPInvestError,
  verifyPInvestSuccess,

  deleteCUsersError,
  deleteCUsersSuccess,

  deletePInvestError,
  deletePInvestSuccess,

  updateCUserSuccess,
  updateCUserError
  } from "redux/actions/cUsers";
import { 
  getAllUsers,
  deleteUser,
  verifyUser,
  
  p_invests,
  delete_pinvests,
  verify_pinvests,

  update_email
} from "api/cUsers";
import {message} from "antd";

function* readCUsers(action) {
  try {
    const response = yield call(getAllUsers, action.payload);
    
    const { error } = response;
    if (error) throw error;

    yield put(getAllCUsersSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error("Error Reading Users.");

    yield put(getAllCUsersError(error));
  }
}

function* watchReadCUsers() {
  yield takeEvery(types.cUsers.GET_ALL_CUSERS, readCUsers);
}


function* verifyCUsersSaga(action) {
  try {
    const response = yield call(verifyUser, action.payload);
    
    const { error } = response;
    if (error) throw error;

    yield put(verifyCUsersSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error("Error verifying Users.");

    yield put(verifyCUsersError(error));
  }
}

function* watchVerifyCUsers() {
  yield takeEvery(types.cUsers.VERIFY_CUSER, verifyCUsersSaga);
}


function* deleteCUsers(action) {
  try {
    const response = yield call(deleteUser, action.payload);
    
    const { error } = response;
    if (error) throw error;

    message.success("User Successfully Deleted.");
    yield put(deleteCUsersSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error("Error Deleting User");

    yield put(deleteCUsersError(error));
  }
}

function* watchDeleteCUsers() {
  yield takeEvery(types.cUsers.DELETE_CUSER, deleteCUsers);
}


function* readPInvest(action) {
  try {
    const response = yield call(p_invests, action.payload);
    
    const { error } = response;
    if (error) throw error;

    yield put(getAllPInvestSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error("Error reading invests.");

    yield put(getAllPInvestError(error));
  }
}

function* watchReadPInvest() {
  yield takeEvery(types.cUsers.GET_ALL_PINVEST, readPInvest);
}


function* verifyPInvest(action) {
  try {
    const response = yield call(verify_pinvests, action.payload);
    
    const { error } = response;
    if (error) throw error;

    yield put(verifyPInvestSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error("Error verifying Invest.");

    yield put(verifyPInvestError(error));
  }
}

function* watchVerifyPInvest() {
  yield takeEvery(types.cUsers.VERIFY_PINVEST, verifyPInvest);
}


function* deletePInvest(action) {
  try {
    const response = yield call(delete_pinvests, action.payload);
    
    const { error } = response;
    if (error) throw error;

    message.success("Invest Successfully Deleted.");
    yield put(deletePInvestSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error("Error Deleting Pending Invest.");

    yield put(deletePInvestError(error));
  }
}

function* watchDeletePInvest() {
  yield takeEvery(types.cUsers.DELETE_PINVEST, deletePInvest);
}

function* updateCUserSaga(action) {
  try {
    const response = yield call(update_email, action.payload);
    
    const { error } = response;
    if (error) throw error;

    message.success("User's Email Successfully Updated.");
    yield put(updateCUserSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error("Error verifying Invest.");

    yield put(updateCUserError(error));
  }
}

function* watchUpdateCUser() {
  yield takeEvery(types.cUsers.UPDATE_CUSERS, updateCUserSaga);
}



//add all the saga
export default function* userSaga() {
  yield all([
    watchReadCUsers(), 
    watchVerifyCUsers(), 
    watchDeleteCUsers(),
    watchReadPInvest(), 
    watchVerifyPInvest(), 
    watchDeletePInvest(),
    watchUpdateCUser(),
  ]);
}