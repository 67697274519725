export default {

  UPDATE_RULES: "/rules/update/request",
  UPDATE_RULES_SUCCESS: "/rules/update/success",
  UPDATE_RULES_ERROR: "/rules/update/error",

  READ_DEMO_RULES: "/rules/read_demo/request",
  READ_DEMO_RULES_SUCCESS: "/rules/read_demo/success",
  READ_DEMO_RULES_ERROR: "/rules/read_demo/error",

  READ_FUNDED_RULES: "/rules/read_funded/request",
  READ_FUNDED_RULES_SUCCESS: "/rules/read_funded/success",
  READ_FUNDED_RULES_ERROR: "/rules/read_funded/error",
};