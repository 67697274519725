import types from "redux/types";



const initialState = {
  loading: false,
  success: false,
  data: [],
  error: []
}

const initialUserState = {
  cusers: {
    ...initialState,
  },
  verifycUser:{
    ...initialState,
  },
  deletecUser:{
    ...initialState
  },
  pInvests: {
    ...initialState,
  },
  verifypInvests:{
    ...initialState,
  },
  deletepInvests:{
    ...initialState
  },
  updateCUser:{
    ...initialState
  }
};

export default (state = initialUserState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    //cusers
    case types.cUsers.GET_ALL_CUSERS:
      return {
        ...state,
        cusers: {
          ...initialState,
          loading: true,
        }
      };
    case types.cUsers.GET_ALL_CUSERS_SUCCESS:
      return {
        ...state,
        cusers: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.cUsers.GET_ALL_CUSERS_ERROR:
      return {
        ...state,
        cusers: {
          ...initialState,
          success: false,
          error: payload?.error,
        }
      };
    //verifycUser
    case types.cUsers.VERIFY_CUSER:
      return {
        ...state,
        verifycUser: {
          ...initialState,
          loading: true,
        }
    };
    case types.cUsers.VERIFY_CUSER_SUCCESS:
      return {
        ...state,
        verifycUser: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.cUsers.VERIFY_CUSER_ERROR:
      return {
        ...state,
        verifycUser: {
          ...initialState,
          success: false,
          error: payload?.error,
        }
      };
    //deletecUser
    case types.cUsers.DELETE_CUSER:
    return {
      ...state,
      deletecUser: {
        ...initialState,
        loading: true,
      }
    };
    case types.cUsers.DELETE_CUSER_SUCCESS:
      return {
        ...state,
        deletecUser: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.cUsers.DELETE_CUSER_ERROR:
      return {
        ...state,
        deletecUser: {
          ...initialState,
          success: false,
          error: payload?.error,
        }
      };
    //pInvests
    case types.cUsers.GET_ALL_PINVEST:
    return {
      ...state,
      pInvests: {
        ...initialState,
        loading: true,
      }
    };
    case types.cUsers.GET_ALL_PINVEST_SUCCESS:
      return {
        ...state,
        pInvests: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.cUsers.GET_ALL_PINVEST_ERROR:
      return {
        ...state,
        pInvests: {
          ...initialState,
          success: false,
          error: payload?.error,
        }
      };
    //verifypInvests
    case types.cUsers.VERIFY_PINVEST:
    return {
      ...state,
      verifypInvests: {
        ...initialState,
        loading: true,
      }
    };
    case types.cUsers.VERIFY_PINVEST_SUCCESS:
      return {
        ...state,
        verifypInvests: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.cUsers.VERIFY_PINVEST_ERROR:
      return {
        ...state,
        verifypInvests: {
          ...initialState,
          success: false,
          error: payload?.error,
        }
      };
    //deletepInvests
    case types.cUsers.DELETE_PINVEST:
    return {
      ...state,
      deletepInvests: {
        ...initialState,
        loading: true,
      }
    };
    case types.cUsers.DELETE_PINVEST_SUCCESS:
      return {
        ...state,
        deletepInvests: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.cUsers.DELETE_PINVEST_ERROR:
      return {
        ...state,
        deletepInvests: {
          ...initialState,
          success: false,
          error: payload?.error,
        }
      };
    case types.cUsers.UPDATE_CUSERS:
      return {
        ...state,
        updateCUser: {
          ...initialState,
          loading: true,
        }
      };
    case types.cUsers.UPDATE_CUSERS_SUCCESS:
      return {
        ...state,
        updateCUser: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.cUsers.UPDATE_CUSERS_ERROR:
      return {
        ...state,
        updateCUser: {
          ...initialState,
          success: false,
          error: payload?.error,
        }
      };
    default:
      return state;
  }
};