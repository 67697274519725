import {
  get,
  post,
  remove
} from "utils/request";

export async function updateChallengeAPI(payload) {
  const {
    accessToken,
    id,
    data
  } = payload;
  return post(`/challenges/${id}`, data, accessToken);
}

export async function createChallengeAPI(payload) {
  const {
    accessToken,
    data
  } = payload;
  return post(`/challenges`, data, accessToken);
}

export async function deleteChallengeAPI(payload) {
  const {
    accessToken,
    id
  } = payload;
  return remove(`/challenges/${id}`, accessToken);
}

export async function updateChallengeStatusAPI(payload) {
  const {
    accessToken,
    account_number
  } = payload;
  return get(`/challenges/${account_number}`, accessToken);
}