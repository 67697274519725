import React, { useEffect, useState } from "react";
import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import TodoList from "./TodoList.jsx";
import "./TodoModal.scss";


export default function TodoModal({ readTodo, editTodo, deleteTodo, createTodo, create_todo, edit_todo, delete_todo,
  accessToken, theme, showModal = false, loading = false, confirm, setShow, todo, editTodoUser, edit_todo_user}) {
  const modal = Modal;

  useEffect(() => {
    readTodo(accessToken);
  }, [accessToken])

  const todos = Array.isArray(todo?.data?.todos) ? todo.data.todos : [];
  const tags = Array.isArray(todo?.data?.tags) ? todo.data.tags : [];
  const all_tags = Array.isArray(todo?.data?.all_tags) ? todo.data.all_tags : [];

  return (
    <Modal
      title={<span style={{ fontSize: '1.5rem', margin: '1rem' }}>Todo List:</span>}
      visible={showModal}
      onOk={confirm}
      onCancel={() => { setShow(false); modal.destroyAll(); }}
      confirmLoading={loading}
      closable={true}
      width='50%'
      footer={false}
      className="todo-modal"
    >
      <TodoList
        tags={all_tags}
        all_tags={all_tags}
        todos={todos}
        accessToken={accessToken}
        createTodo={createTodo}
        editTodo={editTodo}
        deleteTodo={deleteTodo}
        create_todo={create_todo}
        edit_todo={edit_todo}
        delete_todo={delete_todo}
        edit_todo_user={edit_todo_user}
        editTodoUser={editTodoUser}
      />
    </Modal>
  );
}
