import types from "redux/types";

export const readFundedAccountInit = (payload) => {
  return {
    type: types.fundedAccounts.READ_FUNDED_ACCOUNTS,
    payload,
  };
};
export const readFundedAccountSuccess = (payload) => {
  return {
    type: types.fundedAccounts.READ_FUNDED_ACCOUNTS_SUCCESS,
    payload,
  };
};
export const readFundedAccountError = (payload) => {
  return {
    type: types.fundedAccounts.READ_FUNDED_ACCOUNTS_ERROR,
    payload,
  };
};

