import {
  get,
  post,
  remove
} from "utils/request";

export async function getAllUsers({accessToken}) {
  return get("/creport/users", accessToken);
}

export async function deleteUser(payload) {
  const {id, accessToken} = payload;
  return remove("/creport/user/" + id, accessToken);
}

export async function verifyUser({id, accessToken}) {
  return get("/creport/verify/" + id, accessToken);
}

export async function p_invests({accessToken}) {
  return get("/creport/p_invests", accessToken);
}

export async function delete_pinvests({id, accessToken}) {
  return remove("/creport/p_invests/" + id, accessToken);
}

export async function verify_pinvests({data, accessToken}) {
  return post("/creport/verify_invest", data, accessToken);
}

export async function update_email({id, data, accessToken}) {
  return post("/cusers/update/"+id, data, accessToken);
}