import { combineReducers } from "redux";

import auth from "redux/reducers/auth";
import currentUser from "redux/reducers/currentUser";
import theme from "redux/reducers/theme";
import users from "redux/reducers/users";
import cUsers from "redux/reducers/cUsers";
import accounts from "redux/reducers/accounts";
import statistics from "redux/reducers/statistics";
import challenges from "redux/reducers/challenges";
import fundedAccounts from "redux/reducers/fundedAccounts";
import dashboard from "redux/reducers/dashboard";
import rules from "redux/reducers/rules";
import iaccounts from "redux/reducers/investors";
import portfolio from "redux/reducers/portfolio";
import todo from "redux/reducers/todo";


const reducers = combineReducers({
  auth,
  currentUser,
  theme,
  users,
  accounts,
  statistics,
  challenges,
  fundedAccounts,
  dashboard,
  rules,
  iaccounts,
  portfolio,
  cUsers,
  todo
});

export default reducers;
