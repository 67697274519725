import types from "redux/types";

const initialState = {
    slider: false, 
    theme: 'light'
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.theme.CURRENT_SLIDER_STATUS:
      return {
        ...state,
        slider: payload,
      }

    case types.theme.CURRENT_THEME:
      return {
        ...state,
        theme: payload,
      };

    default:
      return state;
  }
};
