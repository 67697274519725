import { get, post } from "utils/request";

export async function login(loginData) {
  return post("/auth/login", loginData);
}

export async function signup(signupData) {
  return post("/auth/register", signupData);
}

export async function verifyEmail(verificationToken) {
  return get(`/auth/email-verification/${verificationToken}`);
}

export async function forgotPassword(forgotPasswordParams) {
  return post("/auth/reset-password-link", forgotPasswordParams);
}

export async function resetPassword(resetPasswordParams) {
  return post("/auth/reset-password", resetPasswordParams);
}