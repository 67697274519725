
export const createAccount = (state) => {
  return state.accounts.create;
};

export const updateAccount = (state) => {
  return state.accounts.update;
};

export const readAccount = (state) => {
  return state.accounts.read;
};

export const deleteAccount = (state) => {
  return state.accounts.delete;
};

export const readAllAccount = (state) => {
  return state.accounts.readAll;
};

export const readUsers = (state) => {
  return state.accounts.readUsers;
};

export const readBrokers = (state) => {
  return state.accounts.readBrokers;
};

export const runAccounts = (state) => {
  return state.accounts.runAccounts;
};

export const stopAccounts = (state) => {
  return state.accounts.stopAccounts;
};