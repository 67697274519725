import types from "redux/types";

export const getAllUsersRequest = (payload) => {
  return {
    type: types.users.GET_ALL_USERS,
    payload,
  };
};

export const getAllUsersSuccess = (payload) => {
  return {
    type: types.users.GET_ALL_USERS_SUCCESS,
    payload,
  };
};

export const getAllUsersError = (error) => {
  return {
    type: types.users.GET_ALL_USERS_ERROR,
    payload: error,
  };
};

export const getAllUsersReset = () => {
  return {
    type: types.users.GET_ALL_USERS_RESET,
  };
};


export const deleteUser = (accessToken, id) => {
  return {
    type: types.users.DELETE_USER,
    accessToken,
    id
  };
};

export const deleteUserSuccess = (payload) => {
  return {
    type: types.users.DELETE_USER_SUCCESS,
    payload,
  };
};

export const deleteUserError = (error) => {
  return {
    type: types.users.DELETE_USER_ERROR,
    payload: error,
  };
};

export const editUser = (payload) => {
  return {
    type: types.users.EDIT_USER,
    payload,
  };
};

export const editUserSuccess = (payload) => {
  return {
    type: types.users.EDIT_USER_SUCCESS,
    payload,
  };
};

export const editUserError = (error) => {
  return {
    type: types.users.EDIT_USER_ERROR,
    payload: error,
  };
};