
export const readAccountStatistics = (state) => {
  return state.statistics.readStatistics;
};

export const readFundedAccountStatistics = (state) => {
  return state.statistics.readFundedStatistics;
};

export const readAccountStatisticsDate = (state) => {
  return state.statistics.readStatisticsDate;
};

export const readAccountProps = (state) => {
  return state.statistics.readProps;
};