export default {
    
    GET_ALL_CUSERS: "/cusers/request",
    GET_ALL_CUSERS_SUCCESS: "/cusers/success",
    GET_ALL_CUSERS_ERROR: "/cusers/error",
    
    UPDATE_CUSERS: "/cusers/update/request",
    UPDATE_CUSERS_SUCCESS: "/cusers/update/success",
    UPDATE_CUSERS_ERROR: "/cusers/update/error",
    
    VERIFY_CUSER: "/cuser/verify/request",
    VERIFY_CUSER_SUCCESS: "/cuser/verify/success",
    VERIFY_CUSER_ERROR: "/cuser/verify/error",

    DELETE_CUSER: "/cuser/delete/request",
    DELETE_CUSER_SUCCESS: "/cuser/delete/success",
    DELETE_CUSER_ERROR: "/cuser/delete/error",
    
    GET_ALL_PINVEST: "/pinvest/request",
    GET_ALL_PINVEST_SUCCESS: "/pinvest/success",
    GET_ALL_PINVEST_ERROR: "/pinvest/error",

    VERIFY_PINVEST: "/pinvest/verify/request",
    VERIFY_PINVEST_SUCCESS: "/pinvest/verify/success",
    VERIFY_PINVEST_ERROR: "/pinvest/verify/error",

    DELETE_PINVEST: "/pinvest/delete/request",
    DELETE_PINVEST_SUCCESS: "/pinvest/delete/success",
    DELETE_PINVEST_ERROR: "/pinvest/delete/error",
  };