import React from "react";
import Logo from './logo.png';
import "./PageLoader.scss";
export default (props) => {
  return (
    <div className="page--loader">
      <div className="loader-box">
        <div className="img-box">
          <img width="70" height="70" src={Logo} alt="Erba Trading Logo" />
        </div>
        <div className="loader"/>
      </div>
    </div>
  );
};
