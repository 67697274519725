import auth from "redux/types/auth";

const getAuthState = (state) => state.auth;

export const getAccessToken = (state) => {
  const authState = getAuthState(state);
  return authState.login.data.accessToken;
};
export const getCurrentUserRole = (state) => {
  const authState = getAuthState(state);
  return authState.login.data.role;
};
export const getSelfUserID = (state) => {
  const authState = getAuthState(state);
  return authState.login.data.userID;
};
export const getDidSignup = (state) => {
  const authState = getAuthState(state);
  return authState.signup.success;
};
export const getSignUpError = (state) => {
  const authState = getAuthState(state);
  return authState.signup.error;
};
export const getIsSignupError = (state) => {
  const authState = getAuthState(state);
  return !!authState.signup.error.message;
};
export const getIsLoggedIn = (state) => {
  const authState = getAuthState(state);
  return !!(authState.login &&  authState.login.data && authState.login.data.accessToken);
};
export const getIsLoggedInLoading = (state) => {
  const authState = getAuthState(state);
  return authState.login && authState.login.loading;
};
export const getIsSignupLoading = (state) => {
  const authState = getAuthState(state);
  return !!authState.signup.loading;
};
export const getIsLoginError = (state) => {
  const authState = getAuthState(state);
  return !!(authState.login && authState.login.error && authState.login.error.message);
};
export const getLoginError = (state) => {
  const authState = getAuthState(state);
  return authState.login && authState.login.error;
};
export const getIsEmailVerificationSuccess = (state) => {
  const authState = getAuthState(state);
  return authState.emailVerify.success;
};
export const getIsEmailVerificationLoading = (state) => {
  const authState = getAuthState(state);
  return authState.emailVerify.loading;
};
export const getIsLogoutLoading = (state) => {
  const authState = getAuthState(state);
  return authState.logout && authState.logout.loading;
};

export const forgotPassword = (state) => {
  return state.auth.forgotPassword;
};

export const resetPassword = (state) => {
  return state.auth.resetPassword;
};