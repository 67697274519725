import { all } from "redux-saga/effects";

import authSaga from "redux/sagas/auth";
import currentUserSaga from "redux/sagas/currentUser";
import users from "redux/sagas/users";
import cUsers from "redux/sagas/cUsers";
import accounts from "redux/sagas/accounts";
import statistics from "redux/sagas/statistics";
import challenges from "redux/sagas/challenges";
import fundedAccounts from "redux/sagas/fundedAccounts";
import dashboard from "redux/sagas/dashboard";
import rules from "redux/sagas/rules";
import iaccounts from "redux/sagas/investors";
import portfolio from "redux/sagas/portfolio";
import todo from "redux/sagas/todo";


export default function* rootSaga() {
  yield all([
    authSaga(), 
    currentUserSaga(), 
    users(),
    accounts(),
    statistics(),
    challenges(),
    fundedAccounts(),
    dashboard(),
    rules(),
    iaccounts(),
    portfolio(),
    cUsers(),
    todo(),
  ]);
}
