import types from "redux/types";

export const getCurrentUserRequest = (payload) => {
  return {
    type: types.currentUser.GET_CURRENT_USER_REQUEST,
    payload,
  };
};
export const getCurrentUserSuccess = (payload) => {
  return {
    type: types.currentUser.GET_CURRENT_USER_SUCCESS,
    payload,
  };
};
export const getCurrentUserError = (payload) => {
  return {
    type: types.currentUser.GET_CURRENT_USER_ERROR,
    payload,
  };
};

export const updateUserInfoRequest = (payload) => {
  return {
    type: types.currentUser.UPDATE_USER_DATA_REQUEST,
    payload,
  };
};
export const updateUserInfoSuccess = (payload) => {
  return {
    type: types.currentUser.UPDATE_USER_DATA_SUCCESS,
    payload,
  };
};
export const updateUserInfoError = (payload) => {
  return {
    type: types.currentUser.UPDATE_USER_DATA_ERROR,
    payload,
  };
}

export const clearUpdateUserError = () => {
  return {
    type: types.currentUser.CLEAR_UPDATE_USER_ERROR,
  };
};
export const clearCurrentUser = () => {
  return {
    type: types.currentUser.CLEAR,
  };
};
