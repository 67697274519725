
export const updateRules = (state) => {
  return state.rules.update;
};

export const readRulesDemo = (state) => {
  return state.rules.readDemo;
};

export const readRulesFunded = (state) => {
  return state.rules.readFunded;
};