import {
  all,
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import types from "redux/types";
import {
  readFundedAccountError,
  readFundedAccountSuccess,
} from "redux/actions/fundedAccounts";

import {
  readAllAccountsReq,
} from "api/fundedAccounts";

import {
  message
} from "antd";


function* readAllAccounts(action) {
  try {
    const response = yield call(readAllAccountsReq, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    yield put(readFundedAccountSuccess(response));

  } catch (error) {
    message.error('Error Reading Accounts. ' + error?.message.toString());
    yield put(readFundedAccountError(error));
  }
}

function* readAllFundedAccountsSaga() {
  yield takeEvery(types.fundedAccounts.READ_FUNDED_ACCOUNTS, readAllAccounts);
}



//add all the saga
export default function* accountsSaga() {
  yield all([
    readAllFundedAccountsSaga(),
  ]);
}