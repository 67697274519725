import types from "redux/types";



const initialState = {
  loading: false,
  success: false,
  data: [],
  error: []
}

const initialUserState = {
  readStatistics: {
    ...initialState,
  },
  readFundedStatistics: {
    ...initialState,
  },
  readStatisticsDate: {
    ...initialState,
  },
  readProps: {
    ...initialState,
  },
};

export default (state = initialUserState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {

    //read Statisctics
    case types.statistics.READ_STATISTICS:
      return {
        ...state,
        readStatistics: {
          ...initialState,
          loading: true,
        }
      };
    case types.statistics.READ_STATISTICS_SUCCESS:
      return {
        ...state,
        readStatistics: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.statistics.READ_STATISTICS_ERROR:
      return {
        ...state,
        readStatistics: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };

      //read funded Statisctics
    case types.statistics.READ_FUNDED_STATISTICS:
      return {
        ...state,
        readFundedStatistics: {
          ...initialState,
          loading: true,
        }
      };
    case types.statistics.READ_FUNDED_STATISTICS_SUCCESS:
      return {
        ...state,
        readFundedStatistics: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.statistics.READ_FUNDED_STATISTICS_ERROR:
      return {
        ...state,
        readFundedStatistics: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };

      //read Statisctics date
    case types.statistics.READ_STATISTICS_DATE:
      return {
        ...state,
        readStatisticsDate: {
          ...initialState,
          loading: true,
        }
      };
    case types.statistics.READ_STATISTICS_DATE_SUCCESS:
      return {
        ...state,
        readStatisticsDate: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.statistics.READ_STATISTICS_DATE_ERROR:
      return {
        ...state,
        readStatisticsDate: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };

      //read Statisctics
    case types.statistics.READ_PROPS:
      return {
        ...state,
        readProps: {
          ...initialState,
          loading: true,
        }
      };
    case types.statistics.READ_PROPS_SUCCESS:
      return {
        ...state,
        readProps: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.statistics.READ_PROPS_ERROR:
      return {
        ...state,
        readProps: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };

    default:
      return state;
  }
};