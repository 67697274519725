import types from "redux/types";

const initialState = {
  loading: false,
  success: false,
  data: [],
  error: []
}

const initialUserState = {
  read_todo: {
    ...initialState,
  },
  create_todo: {
    ...initialState,
  },
  edit_todo: {
    ...initialState,
  },
  delete_todo: {
    ...initialState,
  },
  edit_todo_user: {
    ...initialState,
  },
  create_todo_comment: {
    ...initialState,
  },
  edit_todo_comment: {
    ...initialState,
  },
  read_todo_comment: {
    ...initialState,
  },
  delete_todo_comment: {
    ...initialState,
  },
  create_trade_comment: {
    ...initialState,
  },
  edit_trade_comment: {
    ...initialState,
  },
  read_trade_comment: {
    ...initialState,
  },
  read_trades: {
    ...initialState,
  },
  delete_trade_comment: {
    ...initialState,
  },
  l_log:{
    ...initialState,
  },
  uncommented:{
    ...initialState,
  },
  summary:{
    ...initialState,
  }
};

export default (state = initialUserState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {

    //read
    case types.todo.READ_TODO:
      return {
        ...state,
        read_todo: {
          ...initialState,
          loading: true,
        }
      };
    case types.todo.READ_TODO_SUCCESS:
      return {
        ...state,
        read_todo: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.todo.READ_TODO_ERROR:
      return {
        ...state,
        read_todo: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    //CREATE
    case types.todo.CREATE_TODO:
      return {
        ...state,
        create_todo: {
          ...initialState,
          loading: true,
        }
      };
    case types.todo.CREATE_TODO_SUCCESS:
      return {
        ...state,
        create_todo: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.todo.CREATE_TODO_ERROR:
      return {
        ...state,
        create_todo: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    //read
    case types.todo.EDIT_TODO:
      return {
        ...state,
        edit_todo: {
          ...initialState,
          loading: true,
        }
      };
    case types.todo.EDIT_TODO_SUCCESS:
      return {
        ...state,
        edit_todo: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.todo.EDIT_TODO_ERROR:
      return {
        ...state,
        edit_todo: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    //delete
    case types.todo.DELETE_TODO:
      return {
        ...state,
        delete_todo: {
          ...initialState,
          loading: true,
        }
      };
    case types.todo.DELETE_TODO_SUCCESS:
      return {
        ...state,
        delete_todo: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.todo.DELETE_TODO_ERROR:
      return {
        ...state,
        delete_todo: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    case types.todo.EDIT_TODO_USER:
      return {
        ...state,
        edit_todo_user: {
          ...initialState,
          loading: true,
        }
      };
    case types.todo.EDIT_TODO_USER_SUCCESS:
      return {
        ...state,
        edit_todo_user: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.todo.EDIT_TODO_USER_ERROR:
      return {
        ...state,
        edit_todo_user: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
      case types.todo.CREATE_TODOCOMMENTS:
        return {
          ...state,
          create_trade_comment: {
            ...initialState,
            loading: true,
          }
        };
      case types.todo.CREATE_TODOCOMMENTS_SUCCESS:
        return {
          ...state,
          create_trade_comment: {
            ...initialState,
            success: true,
            data: payload?.data,
          }
        };
      case types.todo.CREATE_TODOCOMMENTS_ERROR:
        return {
          ...state,
          create_trade_comment: {
            ...initialState,
            success: false,
            error: payload?.data,
          }
        };
      case types.todo.READ_TODOCOMMENTS:
        return {
          ...state,
          read_trade_comment: {
            ...initialState,
            loading: true,
          }
        };
      case types.todo.READ_TODOCOMMENTS_SUCCESS:
        return {
          ...state,
          read_trade_comment: {
            ...initialState,
            success: true,
            data: payload?.data,
          }
        };
      case types.todo.READ_TODOCOMMENTS_ERROR:
        return {
          ...state,
          read_trade_comment: {
            ...initialState,
            success: false,
            error: payload?.data,
          }
        };
      case types.todo.READ_TRADES:
        return {
          ...state,
          read_trades: {
            ...initialState,
            loading: true,
          }
        };
      case types.todo.READ_TRADES_SUCCESS:
        return {
          ...state,
          read_trades: {
            ...initialState,
            success: true,
            data: payload?.data,
          }
        };
      case types.todo.READ_TRADES_ERROR:
        return {
          ...state,
          read_trades: {
            ...initialState,
            success: false,
            error: payload?.data,
          }
        };
      case types.todo.DELETE_TODOCOMMENTS:
        return {
          ...state,
          delete_trade_comment: {
            ...initialState,
            loading: true,
          }
        };
      case types.todo.DELETE_TODOCOMMENTS_SUCCESS:
        return {
          ...state,
          delete_trade_comment: {
            ...initialState,
            success: true,
            data: payload?.data,
          }
        };
      case types.todo.DELETE_TODOCOMMENTS_ERROR:
        return {
          ...state,
          delete_trade_comment: {
            ...initialState,
            success: false,
            error: payload?.data,
          }
        };
      case types.todo.EDIT_TODOCOMMENTS:
        return {
          ...state,
          edit_trade_comment: {
            ...initialState,
            loading: true,
          }
        };
      case types.todo.EDIT_TODOCOMMENTS_SUCCESS:
        return {
          ...state,
          edit_trade_comment: {
            ...initialState,
            success: true,
            data: payload?.data,
          }
        };
      case types.todo.EDIT_TODOCOMMENTS_ERROR:
        return {
          ...state,
          edit_trade_comment: {
            ...initialState,
            success: false,
            error: payload?.data,
          }
        };

      case types.todo.LOGINL:
        return {
          ...state,
          l_log: {
            ...initialState,
            loading: true,
          }
        };
      case types.todo.LOGINL_SUCCESS:
        return {
          ...state,
          l_log: {
            ...initialState,
            success: true,
            data: payload?.data,
          }
        };
      case types.todo.LOGINL_ERROR:
        return {
          ...state,
          l_log: {
            ...initialState,
            success: false,
            error: payload?.data,
          }
        };

      case types.todo.UNCOMMENTED:
        return {
          ...state,
          uncommented: {
            ...initialState,
            loading: true,
          }
        };
      case types.todo.UNCOMMENTED_SUCCESS:
        return {
          ...state,
          uncommented: {
            ...initialState,
            success: true,
            data: payload?.data,
          }
        };
      case types.todo.UNCOMMENTED_ERROR:
        return {
          ...state,
          uncommented: {
            ...initialState,
            success: false,
            error: payload?.data,
          }
        };
      case types.todo.SUMMARY:
        return {
          ...state,
          summary: {
            ...initialState,
            loading: true,
          }
        };
      case types.todo.SUMMARY_SUCCESS:
        return {
          ...state,
          summary: {
            ...initialState,
            success: true,
            data: payload?.data,
          }
        };
      case types.todo.SUMMARY_ERROR:
        return {
          ...state,
          summary: {
            ...initialState,
            success: false,
            error: payload?.data,
          }
        };
    
    default:
      return state;
  }
};