export default {

  CREATE_ACCOUNT: "/account/create/request",
  CREATE_ACCOUNT_SUCCESS: "/account/create/success",
  CREATE_ACCOUNT_ERROR: "/account/create/error",

  UPDATE_ACCOUNT: "/account/update/request",
  UPDATE_ACCOUNT_SUCCESS: "/account/update/success",
  UPDATE_ACCOUNT_ERROR: "/account/update/error",

  READ_ACCOUNT: "/account/read/request",
  READ_ACCOUNT_SUCCESS: "/account/read/success",
  READ_ACCOUNT_ERROR: "/account/read/error",

  DELETE_ACCOUNT: "/account/delete/request",
  DELETE_ACCOUNT_SUCCESS: "/account/delete/success",
  DELETE_ACCOUNT_ERROR: "/account/delete/error",

  READ_ACCOUNTS: "/accounts/read/request",
  READ_ACCOUNTS_SUCCESS: "/accounts/read/success",
  READ_ACCOUNTS_ERROR: "/accounts/read/error",

  //get users
  READ_USERS: "/accounts/users/request",
  READ_USERS_SUCCESS: "/accounts/users/success",
  READ_USERS_ERROR: "/accounts/users/error",

  READ_BROKERS: "/accounts/brokers/request",
  READ_BROKERS_SUCCESS: "/accounts/brokers/success",
  READ_BROKERS_ERROR: "/accounts/brokers/error",

  RUN_ACCOUNTS: "/accounts/run/request",
  RUN_ACCOUNTS_SUCCESS: "/accounts/run/success",
  RUN_ACCOUNTS_ERROR: "/accounts/run/error",

  STOP_ACCOUNTS: "/accounts/stop/request",
  STOP_ACCOUNTS_SUCCESS: "/accounts/stop/success",
  STOP_ACCOUNTS_ERROR: "/accounts/stop/error",
};