import { all, call, put, takeEvery} from "redux-saga/effects";

import types from "redux/types";
import {getAllUsersSuccess, getAllUsersError, deleteUserSuccess, deleteUserError, editUserSuccess, editUserError } from "redux/actions/users";
import { getAllUsers, deleteUser, updateUserInfo} from "api/users";
import {message} from "antd";

function* handleUserRequest(action) {
  try {
    const response = yield call(getAllUsers, action.payload);
    
    const { error } = response;
    if (error) throw error;

    yield put(getAllUsersSuccess(response));

  } catch (error) {
    yield put(getAllUsersError(error));
  }
}

function* watchUserRequest() {
  yield takeEvery(types.users.GET_ALL_USERS, handleUserRequest);
}

function* handleUserEdit(action) {
  try {
    const response = yield call(updateUserInfo, action.payload);
    const { error } = response;
    if (error) throw error;
    message.info('Operation Successful!!!');
    yield put(editUserSuccess(response));

  } catch (error) {
    message.error('Error Editing User. ' + error?.message?.toString());
    yield put(editUserError(error));
  }
}

function* watchUserEdit() {
  yield takeEvery(types.users.EDIT_USER, handleUserEdit);
}

function* handleUserDelete(action) {
  try {
    const response = yield call(deleteUser, {accessToken: action.accessToken, id: action.id});
    const { error } = response;
    if (error) throw error;
    message.info('Succesfully Deleted User!!!');
    yield put(deleteUserSuccess(response));

  } catch (error) {
    message.error('Error Deleting User. ' + error?.message.toString());
    yield put(deleteUserError(error));
  }
}

function* watchUserDelete() {
  yield takeEvery(types.users.DELETE_USER, handleUserDelete);
}

//add all the saga
export default function* userSaga() {
  yield all([watchUserRequest(), watchUserEdit(), watchUserDelete()]);
}