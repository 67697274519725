import {
  all,
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import types from "redux/types";
import {
  readTodoSuccess,
  readTodoError,
  createTodoSuccess,
  createTodoError,
  editTodoSuccess,
  editTodoError,
  deleteTodoSuccess,
  deleteTodoError,
  editTodoUserError,
  editTodoUserSuccess,
  createTodoCommentError,
  createTodoCommentSuccess,
  readTodoCommentError,
  readTodoCommentSuccess,
  editTodoCommentError,
  editTodoCommentSuccess,
  deleteTodoCommentError,
  deleteTodoCommentSuccess,
  readTradesError,
  readTradesSuccess,

  LLogSuccess,
  LLogError,
  UncommentedSuccess,
  UncommentedError,

  SummarySuccess,
  SummaryError
} from "redux/actions/todo";

import {
  readTodo,
  createTodo,
  editTodo,
  deleteTodo,
  editTodoUser,

} from "api/todo";

import {
  getTrades,
  readTodoComment,
  editTodoComment,
  createTodoComment,
  deleteTodoComment,
  loginLogs,
  uncommented,
  summary
} from "api/tradelogs";

import {
  message
} from "antd";

function* readTodoReq(action) {
  try {
    const response = yield call(readTodo, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    //message.info('Client Successfully Created!!!');
    yield put(readTodoSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error reading todo tasks.');
    yield put(readTodoError(error));
  }
}

function* readTodoSaga() {
  yield takeEvery(types.todo.READ_TODO, readTodoReq);
}

function* createTodoReq(action) {
  try {
    const response = yield call(createTodo, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    //message.info('Client Successfully Created!!!');
    yield put(createTodoSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error creating todo tasks.');
    yield put(createTodoError(error));
  }
}

function* createTodoSaga() {
  yield takeEvery(types.todo.CREATE_TODO, createTodoReq);
}

function* editTodoReq(action) {
  try {
    const response = yield call(editTodo, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    //message.info('Client Successfully Created!!!');
    yield put(editTodoSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error editing todo tasks.');
    yield put(editTodoError(error));
  }
}

function* editTodoSaga() {
  yield takeEvery(types.todo.EDIT_TODO, editTodoReq);
}

function* deleteTodoReq(action) {
  try {
    const response = yield call(deleteTodo, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    //message.info('Client Successfully Created!!!');
    yield put(deleteTodoSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error deleting todo tasks.');
    yield put(deleteTodoError(error));
  }
}

function* deleteTodoSaga() {
  yield takeEvery(types.todo.DELETE_TODO, deleteTodoReq);
}

function* editTodoUserReq(action) {
  try {
    const response = yield call(editTodoUser, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    //message.info('Client Successfully Created!!!');
    yield put(editTodoUserSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error updating todo tasks.');
    yield put(editTodoUserError(error));
  }
}

function* editTodoUserSaga() {
  yield takeEvery(types.todo.EDIT_TODO_USER, editTodoUserReq);
}

function* readTodoCommentReq(action) {
  try {
    const response = yield call(readTodoComment, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    yield put(readTodoCommentSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error reading comments.');
    yield put(readTodoCommentError(error));
  }
}

function* readTodoCommentSaga() {
  yield takeEvery(types.todo.READ_TODOCOMMENTS, readTodoCommentReq);
}

function* editTodoCommentReq(action) {
  try {
    const response = yield call(editTodoComment, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    yield put(editTodoCommentSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error editing comments.');
    yield put(editTodoCommentError(error));
  }
}

function* editTodoCommentSaga() {
  yield takeEvery(types.todo.EDIT_TODOCOMMENTS, editTodoCommentReq);
}

function* createTodoCommentReq(action) {
  try {
    const response = yield call(createTodoComment, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    yield put(createTodoCommentSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error adding comments.');
    yield put(createTodoCommentError(error));
  }
}

function* createTodoCommentSaga() {
  yield takeEvery(types.todo.CREATE_TODOCOMMENTS, createTodoCommentReq);
}

function* deleteTodoCommentReq(action) {
  try {
    const response = yield call(deleteTodoComment, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    yield put(deleteTodoCommentSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error deleting comments.');
    yield put(deleteTodoCommentError(error));
  }
}

function* deleteTodoCommentSaga() {
  yield takeEvery(types.todo.DELETE_TODOCOMMENTS, deleteTodoCommentReq);
}


function* readTradesReq(action) {
  try {
    const response = yield call(getTrades, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    yield put(readTradesSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error reading trades.');
    yield put(readTradesError(error));
  }
}

function* readTradesSaga() {
  yield takeEvery(types.todo.READ_TRADES, readTradesReq);
}

function* LLogReq(action) {
  try {
    const response = yield call(loginLogs, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    yield put(LLogSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error on Login Log.');
    yield put(LLogError(error));
  }
}

function* LLogSaga() {
  yield takeEvery(types.todo.LOGINL, LLogReq);
}

function* UncommentedReq(action) {
  try {
    const response = yield call(uncommented, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    yield put(UncommentedSuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error on Uncommented Trades.');
    yield put(UncommentedError(error));
  }
}

function* UncommentedSaga() {
  yield takeEvery(types.todo.UNCOMMENTED, UncommentedReq);
}

function* SummaryReq(action) {
  try {
    const response = yield call(summary, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    yield put(SummarySuccess(response));

  } catch (error) {
    if(error?.message) message.error(error?.message?.toString());
    else if(error?.error) message.error(error?.error?.message?.toString());
    else message.error('Error reading Summary.');
    yield put(SummaryError(error));
  }
}

function* SummarySaga() {
  yield takeEvery(types.todo.SUMMARY, SummaryReq);
}

//add all the saga
export default function* todoSagas() {
  yield all([
    readTodoSaga(),
    createTodoSaga(),
    editTodoSaga(),
    deleteTodoSaga(),
    editTodoUserSaga(),

    readTodoCommentSaga(),
    editTodoCommentSaga(),
    deleteTodoCommentSaga(),
    createTodoCommentSaga(),
    readTradesSaga(),

    LLogSaga(),
    UncommentedSaga(),
    SummarySaga(),
  ]);
}